export const SlideshowStatusCssClasses = ["success", "info", ""];
export const SlideshowsStatusTitles = ["Selling", "Sold"];
export const SlideshowConditionCssClasses = ["success", "danger", ""];
export const SlideshowConditionTitles = ["New", "Used"];
export const defaultSorted = [{ dataField: "id", order: "asc" }];
export const sizePerPageList = [
  { text: "3", value: 3 },
  { text: "5", value: 5 },
  { text: "10", value: 10 }
];
export const initialFilter = {
  searchFields: [],
  search: "",
  sortOrder: "asc", // asc||desc
  sortField: "id",
  pageNumber: 1,
  pageSize: 10
};

import React, { useMemo } from "react";
import {
  Card,
  CardBody,
  CardHeader,
  CardHeaderToolbar,
} from "../../../_metronic/_partials/controls";
import { ModulesDetailFilter } from "./modules-detail-filter/ModulesDetailFilter";
import { ModulesDetailTable } from "./modules-detail-table/ModulesDetailTable";
import { ModulesDetailGrouping } from "./modules-detail-grouping/ModulesDetailGrouping";
import { useModulesDetailUIContext } from "./ModulesDetailUIContext";

export function ModulesDetailCard({ module }) {
  const modulesDetailUIContext = useModulesDetailUIContext();
  const modulesDetailUIProps = useMemo(() => {
    return {
      ids: modulesDetailUIContext.ids,
      queryParams: modulesDetailUIContext.queryParams,
      setQueryParams: modulesDetailUIContext.setQueryParams,
      newModulesDetailButtonClick:
        modulesDetailUIContext.newModulesDetailButtonClick,
      openDeleteModulesDetailDialog:
        modulesDetailUIContext.openDeleteModulesDetailDialog,
      openEditModulesDetailPage:
        modulesDetailUIContext.openEditModulesDetailPage,
    };
  }, [modulesDetailUIContext]);

  return (
    <Card>
      <CardHeader title="Detalles de Modulos">
        <CardHeaderToolbar>
          <button
            type="button"
            className="btn btn-primary"
            onClick={() =>
              modulesDetailUIProps.newModulesDetailButtonClick(module)
            }
          >
            Nuevo Tema
          </button>
        </CardHeaderToolbar>
      </CardHeader>
      <CardBody>
        <ModulesDetailFilter />
        {modulesDetailUIProps.ids.length > 0 && (
          <>
            <ModulesDetailGrouping />
          </>
        )}
        <ModulesDetailTable module={module} />
      </CardBody>
    </Card>
  );
}

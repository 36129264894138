/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */
import React from "react";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import SVG from "react-inlinesvg";
import { toAbsoluteUrl } from "../../../../../_metronic/_helpers";

export const ActionsColumnFormatter = (
  cellContent,
  row,
  rowIndex,
  { openEditProgramModulePage, openDeleteProgramModuleDialog, openModulesDetailPage }
) => (
  <>
    <div>
      <OverlayTrigger
        overlay={<Tooltip id="products-edit-tooltip">Modificar Modulo</Tooltip>}
      >
        <a
          className="btn btn-icon btn-light btn-hover-primary btn-sm mx-3"
          onClick={() => openEditProgramModulePage(row.program_id, row.id)}
        >
          <span className="svg-icon svg-icon-md svg-icon-primary">
            <SVG
              src={toAbsoluteUrl("/media/svg/icons/Communication/Write.svg")}
            />
          </span>
        </a>
      </OverlayTrigger>

      <> </>
      <OverlayTrigger
        overlay={<Tooltip id="products-delete-tooltip">Eliminar Modulo</Tooltip>}
      >
        <a
          className="btn btn-icon btn-light btn-hover-danger btn-sm"
          onClick={() => openDeleteProgramModuleDialog(row.program_id, row.id)}
        >
          <span className="svg-icon svg-icon-md svg-icon-danger">
            <SVG src={toAbsoluteUrl("/media/svg/icons/General/Trash.svg")} />
          </span>
        </a>
      </OverlayTrigger>
    </div>

    <div style={{marginTop: '15px'}}>
    <OverlayTrigger
        overlay={<Tooltip id="products-delete-tooltip">Detalles</Tooltip>}
      >
        <a
          className="btn btn-icon btn-light btn-hover-primary btn-sm"
          onClick={() => openModulesDetailPage(row.id)}
        >
          <span className="svg-icon svg-icon-md svg-icon-primary">
            <SVG src={toAbsoluteUrl("/media/svg/icons/General/Clipboard.svg")} />
          </span>
        </a>
      </OverlayTrigger>
    </div>
  </>
);

import {all} from "redux-saga/effects";
import {combineReducers} from "redux";

import * as auth from "../app/modules/Auth/_redux/authRedux";
import { slideshowsSlice } from "../app/pages/slideshows/_redux/slideshowsSlice";
import { programsSlice } from "../app/pages/programs/_redux/programsSlice"
import { programsCategorySlice } from "../app/pages/programs_category/_redux/programsCategorySlice"
import { programsModuleSlice } from "../app/pages/programs_modules/_redux/programsModuleSlice";
import { modulesDetailSlice }  from "../app/pages/modules_details/_redux/modulesDetailSlice"
import { blogsSlice } from "../app/pages/blogs/_redux/blogsSlice";
import { clientsSlice } from "../app/pages/clients/_redux/clientsSlice";
// import {customersSlice} from "../app/modules/ECommerce/_redux/customers/customersSlice";
// import {productsSlice} from "../app/modules/ECommerce/_redux/products/productsSlice";
// import {remarksSlice} from "../app/modules/ECommerce/_redux/remarks/remarksSlice";
// import {specificationsSlice} from "../app/modules/ECommerce/_redux/specifications/specificationsSlice";

export const rootReducer = combineReducers({
  auth: auth.reducer,
  slideshows: slideshowsSlice.reducer,
  programs: programsSlice.reducer,
  programs_category: programsCategorySlice.reducer,
  programs_module: programsModuleSlice.reducer,
  modules_detail: modulesDetailSlice.reducer,
  blogs: blogsSlice.reducer,
  clients: clientsSlice.reducer,
  // products: productsSlice.reducer,
  // remarks: remarksSlice.reducer,
  // specifications: specificationsSlice.reducer
});

export function* rootSaga() {
  yield all([auth.saga()]);
}

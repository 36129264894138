import React from "react";
import { Route } from "react-router-dom";
import { useSubheader } from "../../../_metronic/layout";
import { ProgramsLoadingDialog } from "./programs-loading-dialog/ProgramsLoadingDialog";
import { ProgramsUIProvider } from "./ProgramsUIContext";
import { ProgramDeleteDialog } from "./program-delete-dialog/ProgramDeleteDialog";
import { ProgramsCard } from "./ProgramsCard";

export default function ProgramPage({ history }) {
  const suhbeader = useSubheader();
  suhbeader.setTitle("Listado Programas");

  const ProgramUIEvents = {
    newProgramButtonClick: () => {
      history.push("/programa/nuevo");
    },
    openEditProgramPage: (id) => {
      history.push(`/programa/${id}/editar`);
    },
    openDeleteProgramDialog: (id) => {
      history.push(`/programa/${id}/eliminar`);
    },
    openProgramModulePage: (id) => {
      history.push(`/programa/modulo/${id}`);
    },
  };

  return (
    <ProgramsUIProvider programsUIEvents={ProgramUIEvents}>
      <ProgramsLoadingDialog />
      <Route path="/programa/:id/eliminar">
        {({ history, match }) => (
          <ProgramDeleteDialog
            show={match != null}
            id={match && match.params.id}
            onHide={() => {
              history.push("/programa/");
            }}
          />
        )}
      </Route>
      <ProgramsCard />
    </ProgramsUIProvider>
  );
}

// React bootstrap table next =>
// DOCS: https://react-bootstrap-table.github.io/react-bootstrap-table2/docs/
// STORYBOOK: https://react-bootstrap-table.github.io/react-bootstrap-table2/storybook/index.html
import React, { useEffect, useMemo } from "react";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory, {
  PaginationProvider,
} from "react-bootstrap-table2-paginator";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import * as actions from "../_redux/programsModuleActions";
import * as uiHelpers from "../ProgramsModuleUIHelpers";
import {
  getHandlerTableChange,
  NoRecordsFoundMessage,
  PleaseWaitMessage,
  sortCaret,
} from "../../../../_metronic/_helpers";
import * as columnFormatters from "./column-module-formatters";
import { Pagination } from "../../../../_metronic/_partials/controls";
import { useProgramsModuleUIContext } from "../ProgramsModuleUIContext";

export function ProgramsModuleTable({ program }) {
  // console.log(history);
  // Programs UI Context
  const programsModuleUIContext = useProgramsModuleUIContext();
  const programsModuleUIProps = useMemo(() => {
    return {
      ids: programsModuleUIContext.ids,
      setIds: programsModuleUIContext.setIds,
      queryParams: programsModuleUIContext.queryParams,
      setQueryParams: programsModuleUIContext.setQueryParams,
      openEditProgramModulePage:
        programsModuleUIContext.openEditProgramModulePage,
      openDeleteProgramModuleDialog:
        programsModuleUIContext.openDeleteProgramModuleDialog,
      openModulesDetailPage: programsModuleUIContext.openModulesDetailPage,
    };
  }, [programsModuleUIContext]);

  // Getting curret state of programs list from store (Redux)
  const { currentState } = useSelector(
    (state) => ({ currentState: state.programs_module }),
    shallowEqual
  );
  const { totalCount, entities, listLoading } = currentState;
  // Programs Redux state
  const dispatch = useDispatch();
  useEffect(() => {
    // clear selections list
    programsModuleUIProps.setIds([]);
    // server call by queryParams
    dispatch(
      actions.fetchProgramsModule(program, programsModuleUIProps.queryParams)
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [programsModuleUIProps.queryParams, dispatch]);
  // Table columns
  const columns = [
    {
      dataField: "program",
      text: "Programa",
    },
    {
      dataField: "title",
      text: "Titulo",
    },
    {
      dataField: "duration",
      text: "Duración (Hrs)",
      sort: true,
      sortCaret: sortCaret,
    },
    {
      dataField: "position",
      text: "Posición",
      sort: true,
      sortCaret: sortCaret,
    },
    {
      dataField: "action",
      text: "Opciones",
      formatter: columnFormatters.ActionsColumnFormatter,
      formatExtraData: {
        openEditProgramModulePage:
          programsModuleUIProps.openEditProgramModulePage,
        openDeleteProgramModuleDialog:
          programsModuleUIProps.openDeleteProgramModuleDialog,
        openModulesDetailPage: programsModuleUIProps.openModulesDetailPage,
      },
      classes: "text-right pr-0",
      headerClasses: "text-right pr-3",
      style: {
        minWidth: "100px",
      },
    },
  ];

  const customTotal = (from, to, size) => (
    <span className="react-bootstrap-table-pagination-total">
      Mostrando {from} a {to} de {size} Registros
    </span>
  );

  // Table pagination properties
  const paginationOptions = {
    custom: true,
    totalSize: totalCount,
    sizePerPageList: uiHelpers.sizePerPageList,
    sizePerPage: programsModuleUIProps.queryParams.pageSize,
    page: programsModuleUIProps.queryParams.pageNumber,
    paginationTotalRenderer: customTotal,
  };

  const entitiesClean = (entities || []).filter(function(element) {
    return element !== undefined;
  });
  return (
    <>
      <PaginationProvider pagination={paginationFactory(paginationOptions)}>
        {({ paginationProps, paginationTableProps }) => {
          return (
            <Pagination
              isLoading={listLoading}
              paginationProps={paginationProps}
            >
              <BootstrapTable
                wrapperClasses="table-responsive"
                classes="table table-head-custom table-vertical-center overflow-hidden"
                bootstrap4
                bordered={false}
                remote
                keyField="id"
                data={entitiesClean === null ? [] : entitiesClean}
                columns={columns}
                defaultSorted={uiHelpers.defaultSorted}
                onTableChange={getHandlerTableChange(
                  programsModuleUIProps.setQueryParams
                )}
                {...paginationTableProps}
              >
                <PleaseWaitMessage entities={entitiesClean} />
                <NoRecordsFoundMessage entities={entitiesClean} />
              </BootstrapTable>
            </Pagination>
          );
        }}
      </PaginationProvider>
    </>
  );
}

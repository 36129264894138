import React, { useMemo } from "react";
import {
  Card,
  CardBody,
  CardHeader,
  CardHeaderToolbar,
} from "../../../_metronic/_partials/controls";
import { BlogsFilter } from "./blogs-filter/BlogsFilter";
import { BlogsTable } from "./blogs-table/BlogsTable";
import { BlogsGrouping } from "./blogs-grouping/BlogsGrouping";
import { useBlogsUIContext } from "./BlogsUIContext";

export function BlogsCard() {
  const blogsUIContext = useBlogsUIContext();
  const blogsUIProps = useMemo(() => {
    return {
      ids: blogsUIContext.ids,
      queryParams: blogsUIContext.queryParams,
      setQueryParams: blogsUIContext.setQueryParams,
      newBlogButtonClick: blogsUIContext.newBlogButtonClick,
      openDeleteBlogDialog: blogsUIContext.openDeleteBlogDialog,
      openEditBlogPage: blogsUIContext.openEditBlogPage,
    };
  }, [blogsUIContext]);

  return (
    <Card>
      <CardHeader title="Noticias">
        <CardHeaderToolbar>
          <button
            type="button"
            className="btn btn-primary"
            onClick={blogsUIProps.newBlogButtonClick}
          >
            Nueva Noticia
          </button>
        </CardHeaderToolbar>
      </CardHeader>
      <CardBody>
        <BlogsFilter />
        {blogsUIProps.ids.length > 0 && (
          <>
            <BlogsGrouping />
          </>
        )}
        <BlogsTable />
      </CardBody>
    </Card>
  );
}

// import React, { Suspense, lazy } from "react";
import React, { Suspense } from "react";
// import { Redirect, Switch, Route } from "react-router-dom";
import { Redirect, Switch } from "react-router-dom";
import { LayoutSplashScreen, ContentRoute } from "../_metronic/layout";
import { BuilderPage } from "./pages/BuilderPage";
import { MyPage } from "./pages/MyPage";
import { DashboardPage } from "./pages/DashboardPage";
import SlideshowPage from './pages/slideshows/SlideshowPage';
import { SlideshowEdit } from './pages/slideshows/slideshow-edit/SlideshowEdit';
import ProgramPage from './pages/programs/ProgramPage';
import { ProgramEdit } from './pages/programs/program-edit/ProgramEdit';
import ProgramCategoryPage from './pages/programs_category/ProgramCategoryPage';
import { ProgramCategoryEdit } from './pages/programs_category/program-category-edit/ProgramCategoryEdit';
import ProgramModulePage from './pages/programs_modules/ProgramModulePage';
import { ProgramModuleEdit } from './pages/programs_modules/program-module-edit/ProgramModuleEdit';
import ModulesDetailPage from './pages/modules_details/ModulesDetailPage';
import { ModulesDetailEdit } from './pages/modules_details/modules-detail-edit/ModulesDetailEdit';
import BlogPage from './pages/blogs/BlogPage';
import { BlogEdit } from './pages/blogs/blog-edit/BlogEdit';
import ClientPage from './pages/clients/ClientPage';
import { ClientEdit } from './pages/clients/client-edit/ClientEdit';
import Logout from './modules/Auth/pages/Logout'

// const GoogleMaterialPage = lazy(() =>
//   import("./modules/GoogleMaterialExamples/GoogleMaterialPage")
// );
// const ReactBootstrapPage = lazy(() =>
//   import("./modules/ReactBootstrapExamples/ReactBootstrapPage")
// );
// const ECommercePage = lazy(() =>
//   import("./modules/ECommerce/pages/eCommercePage")
// );
// const UserProfilepage = lazy(() =>
//   import("./modules/UserProfile/UserProfilePage")
// )











export default function BasePage() {
  // useEffect(() => {
  //   console.log('Base page');
  // }, []) // [] - is required if you need only one call
  // https://reactjs.org/docs/hooks-reference.html#useeffect

  return (
    <Suspense fallback={<LayoutSplashScreen />}>
      <Switch>
        {
          /* Redirect from root URL to /dashboard. */
          <Redirect exact from="/" to="/dashboard" />
        }
        <ContentRoute path="/dashboard" component={DashboardPage} />
        <ContentRoute path="/builder" component={BuilderPage} />
        <ContentRoute path="/my-page" component={MyPage} />
        {/* Slideshow */}
        <ContentRoute path="/slideshow/nuevo" component={SlideshowEdit} />
        <ContentRoute
          path="/slideshow/:id/editar"
          component={SlideshowEdit}
        />
        <ContentRoute path="/slideshow" component={SlideshowPage} />
        {/* Programa Categoria */}
        <ContentRoute path="/programa/categoria/nuevo" component={ProgramCategoryEdit} />
        <ContentRoute
          path="/programa/categoria/:id/editar"
          component={ProgramCategoryEdit}
        />
        <ContentRoute path="/programa/categoria/" component={ProgramCategoryPage} />
        {/* Modulo Detales*/}
        <ContentRoute path="/programa/modulo/detalle/:module/nuevo" component={ModulesDetailEdit} />
        <ContentRoute
          path="/programa/modulo/detalle/:module/:id/editar"
          component={ModulesDetailEdit}
        />
        <ContentRoute path="/programa/modulo/detalle/:module" component={ModulesDetailPage} />
        {/* Programa Modulo */}
        <ContentRoute path="/programa/modulo/:program/nuevo" component={ProgramModuleEdit} />
        <ContentRoute
          path="/programa/modulo/:program/:id/editar"
          component={ProgramModuleEdit}
        />
        <ContentRoute path="/programa/modulo/:program" component={ProgramModulePage} />
        {/* Programa */}
        <ContentRoute path="/programa/nuevo" component={ProgramEdit} />
        <ContentRoute
          path="/programa/:id/editar"
          component={ProgramEdit}
        />
        <ContentRoute path="/programa" component={ProgramPage} />
        {/* Blog */}
        <ContentRoute path="/blog/nuevo" component={BlogEdit} />
        <ContentRoute
          path="/blog/:id/editar"
          component={BlogEdit}
        />
        <ContentRoute path="/blog" component={BlogPage} />
        {/* Cliente */}
        <ContentRoute path="/cliente/nuevo" component={ClientEdit} />
        <ContentRoute
          path="/cliente/:id/editar"
          component={ClientEdit}
        />
        <ContentRoute path="/cliente" component={ClientPage} />
        {/* <Route path="/google-material" component={GoogleMaterialPage} />
        <Route path="/react-bootstrap" component={ReactBootstrapPage} />
        <Route path="/e-commerce" component={ECommercePage} />
        <Route path="/user-profile" component={UserProfilepage} /> */}
        <Redirect to="error/error-v1" />
        <ContentRoute path="/logout" component={Logout} />
      </Switch>
    </Suspense>
  );
}

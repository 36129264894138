import * as requestFromServer from "./programsModuleCrud";
import {programsModuleSlice, callTypes} from "./programsModuleSlice";

const {actions} = programsModuleSlice;

export const fetchProgramsModule = (program, queryParams) => dispatch => {
  dispatch(actions.startCall({ callType: callTypes.list }));
  return requestFromServer
    .findProgramsModule(program, queryParams)
    .then(response => {
      const { count, data } = response.data;
      dispatch(actions.programsModuleFetched({ count, data }));
    })
    .catch(error => {
      error.clientMessage = "Can't find programs";
      dispatch(actions.catchError({ error, callType: callTypes.list }));
    });
};

export const fetchProgramModule = (id) => dispatch => {
  if (!id) {
    return dispatch(actions.programModuleFetched({ programModuleForEdit: undefined }));
  }

  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
    .getProgramModuleById(id)
    .then(response => {
      const program = response.data;
      dispatch(actions.programModuleFetched({ programModuleForEdit: program }));
    })
    .catch(error => {
      error.clientMessage = "No puedo encontrar el modulo";
      dispatch(actions.catchError({ error, callType: callTypes.action }));
    });
};

export const deleteProgramModule = (id) => dispatch => {
  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
    .deleteProgramModule(id)
    .then(response => {
      dispatch(actions.programModuleDeleted({ id }));
    })
    .catch(error => {
      error.clientMessage = "Can't delete module";
      dispatch(actions.catchError({ error, callType: callTypes.action }));
    });
};

export const createProgramModule = (program, programModuleForCreation) => dispatch => {
  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
    .createProgramModule(program, programModuleForCreation)
    .then(response => {
      const { program } = response.data;
      dispatch(actions.programModuleCreated({ program }));
    })
    .catch(error => {
      error.clientMessage = "No se puede crear el modulo";
      dispatch(actions.catchError({ error, callType: callTypes.action }));
    });
};

export const updateProgramModule = (program_module) => dispatch => {
  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
    .updateProgramModule(program_module)
    .then(() => {
      dispatch(actions.programModuleUpdated({ program_module }));
    })
    .catch(error => {
      console.log("error", error);
      error.clientMessage = "No se puede actualizar el modulo";
      dispatch(actions.catchError({ error, callType: callTypes.action }));
    });
};

// export const updateProductsStatus = (ids, status) => dispatch => {
//   dispatch(actions.startCall({ callType: callTypes.action }));
//   return requestFromServer
//     .updateStatusForProducts(ids, status)
//     .then(() => {
//       dispatch(actions.productsStatusUpdated({ ids, status }));
//     })
//     .catch(error => {
//       error.clientMessage = "Can't update products status";
//       dispatch(actions.catchError({ error, callType: callTypes.action }));
//     });
// };

// export const deleteProducts = ids => dispatch => {
//   dispatch(actions.startCall({ callType: callTypes.action }));
//   return requestFromServer
//     .deleteProducts(ids)
//     .then(() => {
//       dispatch(actions.productsDeleted({ ids }));
//     })
//     .catch(error => {
//       error.clientMessage = "Can't delete products";
//       dispatch(actions.catchError({ error, callType: callTypes.action }));
//     });
// };

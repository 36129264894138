import axios from "axios";

export const BASE_URL = "https://api.v1.qres.com.mx"
export const PROGRAM_URL = `${BASE_URL}/program`;
export const PROGRAM_ADMIN_URL = `${BASE_URL}/program_admin`;
export const PROGRAMS_URL = `${BASE_URL}/programs`;

export const getNameField = (field) => {
  switch (field) {
    case 'full_date':
      return "date"
    default:
      return field
  }
}

// CREATE =>  POST: add a new program to the server
export function createProgram(program) {
  return axios.post(PROGRAM_URL, program, {
    headers: {
      'Content-Type': 'multipart/form-data'
    }
  });
}

// READ
export function getAllPrograms() {
  return axios.get(PROGRAMS_URL);
}

export function getProgramById(programId) {
  return axios.get(`${PROGRAM_ADMIN_URL}/${programId}`);
}

// Method from server should return QueryResultsModel(items: any[], totalsCount: number)
// items => filtered/sorted result
export function findPrograms(qp) {
  const params = {
    offset: qp.pageNumber - 1,
    limit: qp.pageSize,
    field: getNameField(qp.sortField),
    order: qp.sortOrder,
    search_fields: qp.searchFields.join(','),
    search: qp.search
  }
  // console.log("params", params);
  // const response = axios.get(`${PROGRAMS_URL}`, { queryParams });
  const response = axios.get(`${PROGRAMS_URL}`, { params });
  // console.log(response);
  return response;
}

// UPDATE => PUT: update the program on the server
export function updateProgram(program) {
  const id = program.get("id");
  return axios.post(`${PROGRAM_URL}/${id}`, program, {
    headers: {
      'Content-Type': 'multipart/form-data'
    }
  });
}

// DELETE => delete the program from the server
export function deleteProgram(programId) {
  return axios.delete(`${PROGRAM_URL}/${programId}`);
}

// // DELETE Products by ids
// export function deleteProducts(ids) {
//   return axios.post(`${PRODUCTS_URL}/deleteProducts`, { ids });
// }

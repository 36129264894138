import axios from "axios";

export const BASE_URL = "https://api.v1.qres.com.mx"
export const DETAIL_URL = `${BASE_URL}/programs/modules/detail`;
export const DETAILS_URL = `${BASE_URL}/programs/modules/details`;

export const getNameField = (field) => {
  switch (field) {
    case 'full_date':
      return "date"
    default:
      return field
  }
}

// CREATE =>  POST: add a new detail to the server
export function createModulesDetail(moduleId, values) {
  return axios.post(`${DETAIL_URL}/${moduleId}`, values);
}

// READ
export function getAllModulesDetails() {
  return axios.get(DETAILS_URL);
}

export function getModulesDetailById(moduleId) {
  return axios.get(`${DETAIL_URL}/${moduleId}`);
}

// Method from server should return QueryResultsModel(items: any[], totalsCount: number)
// items => filtered/sorted result
export function findModulesDetail(moduleId, qp) {
  const params = {
    offset: qp.pageNumber - 1,
    limit: qp.pageSize,
    field: getNameField(qp.sortField),
    order: qp.sortOrder,
    search_fields: qp.searchFields.join(','),
    search: qp.search
  }
  // console.log("params", params);
  const response = axios.get(`${DETAILS_URL}/?program_modules_id=${moduleId}`, { params });
  // console.log(response);
  return response;
}

// UPDATE => PUT: update the detail on the server
export function updateModulesDetail(detail) {
  const id = detail.get("id");
  return axios.post(`${DETAIL_URL}/update/${id}`, detail);
}

// DELETE => delete the detail from the server
export function deleteModulesDetail(moduleId) {
  return axios.delete(`${DETAIL_URL}/${moduleId}`);
}

// // DELETE Products by ids
// export function deleteProducts(ids) {
//   return axios.post(`${PRODUCTS_URL}/deleteProducts`, { ids });
// }

import React from "react";
import {useField, useFormikContext} from "formik";
import DatePicker from "react-datepicker";
import es from "date-fns/locale/es";
import moment from "moment";


const getFieldCSSClasses = (touched, errors) => {
  const classes = ["form-control"];
  if (touched && errors) {
    classes.push("is-invalid");
  }

  if (touched && !errors) {
    classes.push("is-valid");
  }

  return classes.join(" ");
};

export function DatePickerField({ ...props }) {
  const { setFieldValue, errors, touched } = useFormikContext();
  const [field] = useField(props);
  return (
    <>
      {props.label && <div><label>{props.label}</label></div>}
      <DatePicker
        className={getFieldCSSClasses(touched[field.name], errors[field.name])}
        style={{ width: "100%" }}
        {...field}
        {...props}
        selected={(field.value && moment(moment(field.value).utc().format( "YYYY-MM-DD" )).toDate()) || null}
        onChange={val => {
          setFieldValue(field.name, val);
        }}
        dateFormat="dd-MM-yyyy"
        locale={es}
        value={(field.value && moment(moment(field.value).utc().format( "YYYY-MM-DD" )).toDate()) || null}
      />
      {errors[field.name] && touched[field.name] ? (
        <div className="invalid-datepicker-feedback">
          {errors[field.name].toString()}
        </div>
      ) : (
        <div className="feedback">
          Por favor, introduzca <b>{props.label}</b> en formato "mm-dd-aaaa".
        </div>
      )}
    </>
  );
}

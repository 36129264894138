import React, { useMemo } from "react";
import {
  Card,
  CardBody,
  CardHeader,
  CardHeaderToolbar,
} from "../../../_metronic/_partials/controls";
import { ProgramsModuleFilter } from "./programs-module-filter/ProgramsModuleFilter";
import { ProgramsModuleTable } from "./programs-module-table/ProgramsModuleTable";
import { ProgramsModuleGrouping } from "./programs-module-grouping/ProgramsModuleGrouping";
import { useProgramsModuleUIContext } from "./ProgramsModuleUIContext";

export function ProgramsModuleCard({ program }) {
  const programsModuleUIContext = useProgramsModuleUIContext();
  const programsModuleUIProps = useMemo(() => {
    return {
      ids: programsModuleUIContext.ids,
      queryParams: programsModuleUIContext.queryParams,
      setQueryParams: programsModuleUIContext.setQueryParams,
      newProgramModuleButtonClick:
        programsModuleUIContext.newProgramModuleButtonClick,
      openDeleteProgramModuleDialog:
        programsModuleUIContext.openDeleteProgramModuleDialog,
      openEditProgramModulePage:
        programsModuleUIContext.openEditProgramModulePage,
      openModulesDetailPage: programsModuleUIContext.openModulesDetailPage,
    };
  }, [programsModuleUIContext]);

  return (
    <Card>
      <CardHeader title="Modulos de Programas">
        <CardHeaderToolbar>
          <button
            type="button"
            className="btn btn-primary"
            onClick={() =>
              programsModuleUIProps.newProgramModuleButtonClick(program)
            }
          >
            Nuevo Modulo
          </button>
        </CardHeaderToolbar>
      </CardHeader>
      <CardBody>
        <ProgramsModuleFilter />
        {programsModuleUIProps.ids.length > 0 && (
          <>
            <ProgramsModuleGrouping />
          </>
        )}
        <ProgramsModuleTable program={program} />
      </CardBody>
    </Card>
  );
}

export const validErrors = (responseError, $duplicated) => {
  if (responseError && responseError.hasOwnProperty("data")) {
    const data = responseError.data[0];
    switch (data) {
      case "PDOException":
        alert($duplicated);
        break;

      case "Expired token":
        alert("La sesión ha finalizado.");
        window.location = "/logout";
        break;

      default:
        alert(`Ocurrio un error desconocido, error: ${data}`);
        break;
    }
  }
};

import { createSlice } from "@reduxjs/toolkit";
import { validErrors } from "../../../functions";

const initialModulesDetailState = {
  listLoading: false,
  actionsLoading: false,
  totalCount: 0,
  entities: null,
  modulesDetailForEdit: undefined,
  lastError: null,
};
export const callTypes = {
  list: "list",
  action: "action",
};

export const modulesDetailSlice = createSlice({
  name: "modules_detail",
  initialState: initialModulesDetailState,
  reducers: {
    catchError: (state, action) => {
      // const errorResponse = action.payload.error.response.data[0];
      // console.log(action.payload);

      state.error = `${action.type}: ${action.payload.error}`;
      if (action.payload.callType === callTypes.list) {
        state.listLoading = false;
      } else {
        state.actionsLoading = false;
      }
      validErrors(action.payload.error.response, "");
    },
    startCall: (state, action) => {
      state.error = null;
      if (action.payload.callType === callTypes.list) {
        state.listLoading = true;
      } else {
        state.actionsLoading = true;
      }
    },
    // getProgramsById
    modulesDetailFetched: (state, action) => {
      state.actionsLoading = false;
      state.modulesDetailForEdit = action.payload.modulesDetailForEdit;
      state.error = null;
    },
    // findPrograms
    modulesDetailsFetched: (state, action) => {
      const { count, data } = action.payload;
      state.listLoading = false;
      state.error = null;
      state.entities = data;
      state.totalCount = count;
    },
    // createProgram
    modulesDetailCreated: (state, action) => {
      state.actionsLoading = false;
      state.error = null;
      state.entities.push(action.payload.modules_detail);
    },
    // updateProgram
    modulesDetailUpdated: (state, action) => {
      state.error = null;
      state.actionsLoading = false;
      state.entities = state.entities.map((entity) => {
        if (entity.id === action.payload.modules_detail.id) {
          return action.payload.modules_detail;
        }
        return entity;
      });
    },
    // deleteProgram
    modulesDetailDeleted: (state, action) => {
      state.error = null;
      state.actionsLoading = false;
      state.entities = state.entities.filter(
        (el) => el.id !== action.payload.id
      );
    },
    // deletePrograms
    modulesDetailsDeleted: (state, action) => {
      state.error = null;
      state.actionsLoading = false;
      state.entities = state.entities.filter(
        (el) => !action.payload.ids.includes(el.id)
      );
    },
    // programsUpdateState
    modulesDetailStatusUpdated: (state, action) => {
      state.actionsLoading = false;
      state.error = null;
      const { ids, status } = action.payload;
      state.entities = state.entities.map((entity) => {
        if (ids.findIndex((id) => id === entity.id) > -1) {
          entity.status = status;
        }
        return entity;
      });
    },
  },
});

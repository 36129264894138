import React, { useMemo } from "react";
import { useProgramsUIContext } from "../ProgramsUIContext";

export function ProgramsGrouping() {
  // Programs UI Context
  const programsUIContext = useProgramsUIContext();
  const programsUIProps = useMemo(() => {
    return {
      ids: programsUIContext.ids,
      setIds: programsUIContext.setIds,
      openDeleteProgramsDialog: programsUIContext.openDeleteProductsDialog,
      openFetchProgramsDialog: programsUIContext.openFetchProductsDialog,
      openUpdateProgramsStatusDialog:
        programsUIContext.openUpdateProgramsStatusDialog,
    };
  }, [programsUIContext]);

  return (
    <div className="form">
      <div className="row align-items-center form-group-actions margin-top-20 margin-bottom-20">
        <div className="col-xl-12">
          <div className="form-group form-group-inline">
            <div className="form-label form-label-no-wrap">
              <label className="-font-bold font-danger-">
                <span>
                  Selected records count: <b>{programsUIProps.ids.length}</b>
                </span>
              </label>
            </div>
            <div>
              <button
                type="button"
                className="btn btn-danger font-weight-bolder font-size-sm"
                onClick={programsUIProps.openDeleteProgramsDialog}
              >
                <i className="fa fa-trash"></i> Delete All
              </button>
              &nbsp;
              <button
                type="button"
                className="btn btn-light-primary font-weight-bolder font-size-sm"
                onClick={programsUIProps.openFetchProgramsDialog}
              >
                <i className="fa fa-stream"></i> Fetch Selected
              </button>
              &nbsp;
              <button
                type="button"
                className="btn btn-light-primary font-weight-bolder font-size-sm"
                onClick={programsUIProps.openUpdateProgramsStatusDialog}
              >
                <i className="fa fa-sync-alt"></i> Update Status
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

import React from "react";
import { Route } from "react-router-dom";
import { useSubheader } from "../../../_metronic/layout";
import { ProgramsCategoryLoadingDialog } from "./programs-category-loading-dialog/ProgramsCategoryLoadingDialog";
import { ProgramsCategoryUIProvider } from "./ProgramsCategoryUIContext";
import { ProgramCategoryDeleteDialog } from "./program-category-delete-dialog/ProgramCategoryDeleteDialog";
import { ProgramsCategoryCard } from "./ProgramsCategoryCard";

export default function ProgramCategoryPage({ history }) {
  const suhbeader = useSubheader();
  suhbeader.setTitle("Listado Categoria de Programa");

  const ProgramCategoryUIEvents = {
    newProgramCategoryButtonClick: () => {
      history.push("/programa/categoria/nuevo");
    },
    openEditProgramCategoryPage: (id) => {
      history.push(`/programa/categoria/${id}/editar`);
    },
    openDeleteProgramCategoryDialog: (id) => {
      history.push(`/programa/categoria/${id}/eliminar`);
    },
  };

  return (
    <ProgramsCategoryUIProvider
      programsCategoryUIEvents={ProgramCategoryUIEvents}
    >
      <ProgramsCategoryLoadingDialog />
      <Route path="/programa/categoria/:id/eliminar">
        {({ history, match }) => (
          <ProgramCategoryDeleteDialog
            show={match != null}
            id={match && match.params.id}
            onHide={() => {
              history.push("/programa/categoria/");
            }}
          />
        )}
      </Route>
      <ProgramsCategoryCard />
    </ProgramsCategoryUIProvider>
  );
}

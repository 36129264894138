/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid,jsx-a11y/role-supports-aria-props */
import React, { useEffect, useState, useRef } from "react";
import { useDispatch } from "react-redux";
import { shallowEqual, useSelector } from "react-redux";
import * as actions from "../_redux/modulesDetailActions";
import {
  Card,
  CardBody,
  CardHeader,
  CardHeaderToolbar,
} from "../../../../_metronic/_partials/controls";
import { ModulesDetailEditForm } from "./ModulesDetailEditForm";
import { useSubheader } from "../../../../_metronic/layout";
import { ModalProgressBar } from "../../../../_metronic/_partials/controls";

const initModulesDetail = {
  id: undefined,
  position: 0,
  topic: "",
};

export function ModulesDetailEdit({
  history,
  match: {
    params: { module, id },
  },
}) {
  // Subheader
  const suhbeader = useSubheader();

  // Tabs
  // const [tab, setTab] = useState("basic");
  const [title, setTitle] = useState("");
  const dispatch = useDispatch();
  // const layoutDispatch = useContext(LayoutContext.Dispatch);
  const { actionsLoading, modulesDetailForEdit } = useSelector(
    (state) => ({
      actionsLoading: state.modules_detail.actionsLoading,
      modulesDetailForEdit: state.modules_detail.modulesDetailForEdit,
    }),
    shallowEqual
  );

  useEffect(() => {
    dispatch(actions.fetchModulesDetail(id));
  }, [id, history, dispatch]);

  useEffect(() => {
    let _title = id ? "" : "Nueva Detalle de Modulo";

    if (modulesDetailForEdit && id) {
      _title = "Editar Detalle de Modulo";
    }

    setTitle(_title);
    suhbeader.setTitle(_title);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [modulesDetailForEdit, id]);

  const saveModulesDetail = (values) => {
    // console.log("data3", values);
    let formData = new FormData();
    formData.append("position", values.position);
    formData.append("topic", values.topic);

    if (!id) {
      dispatch(actions.createModulesDetail(module, formData)).then(() =>
        backToModulesDetailList(module)
      );
    } else {
      formData.append("id", values.id);
      dispatch(actions.updateModulesDetail(formData)).then(() =>
        backToModulesDetailList(module)
      );
    }
  };

  const btnRef = useRef();
  const saveModulesDetailClick = () => {
    if (btnRef && btnRef.current) {
      btnRef.current.click();
    }
  };

  const backToModulesDetailList = (module) => {
    history.push(`/programa/modulo/detalle/${module}`);
  };

  return (
    <Card>
      {actionsLoading && <ModalProgressBar />}
      <CardHeader title={title}>
        <CardHeaderToolbar>
          <button
            type="button"
            onClick={() => backToModulesDetailList(module)}
            className="btn btn-light"
          >
            <i className="fa fa-arrow-left"></i>
            Regresar
          </button>
          {`  `}
          <button
            type="submit"
            className="btn btn-primary ml-2"
            onClick={saveModulesDetailClick}
          >
            Guardar
          </button>
        </CardHeaderToolbar>
      </CardHeader>
      <CardBody>
        <div className="mt-5">
          {/* {tab === "basic" && ( */}
          <ModulesDetailEditForm
            actionsLoading={actionsLoading}
            modulesDetail={modulesDetailForEdit || initModulesDetail}
            btnRef={btnRef}
            saveModulesDetail={saveModulesDetail}
          />
        </div>
      </CardBody>
    </Card>
  );
}

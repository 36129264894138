import * as requestFromServer from "./clientsCrud";
import { clientsSlice, callTypes } from "./clientsSlice";

const { actions } = clientsSlice;

export const fetchClients = (queryParams) => (dispatch) => {
  dispatch(actions.startCall({ callType: callTypes.list }));
  return requestFromServer
    .findClients(queryParams)
    .then((response) => {
      const { count, data } = response.data;
      dispatch(actions.clientsFetched({ count, data }));
    })
    .catch((error) => {
      error.clientMessage = "Can't find clients";
      // validResponse(error, history)
      dispatch(actions.catchError({ error, callType: callTypes.list }));
    });
};

export const fetchClient = (id) => (dispatch) => {
  if (!id) {
    return dispatch(actions.clientFetched({ clientForEdit: undefined }));
  }

  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
    .getClientById(id)
    .then((response) => {
      const client = response.data;
      dispatch(actions.clientFetched({ clientForEdit: client }));
    })
    .catch((error) => {
      error.clientMessage = "No puedo encontrar el client";
      dispatch(actions.catchError({ error, callType: callTypes.action }));
    });
};

export const deleteClient = (id) => (dispatch) => {
  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
    .deleteClient(id)
    .then((response) => {
      dispatch(actions.clientDeleted({ id }));
    })
    .catch((error) => {
      // console.log("yxed", error);
      error.clientMessage = "Can't delete client";
      dispatch(actions.catchError({ error, callType: callTypes.action }));
    });
};

export const createClient = (clientForCreation) => (dispatch) => {
  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
    .createClient(clientForCreation)
    .then((response) => {
      const { client } = response.data;
      dispatch(actions.clientCreated({ client }));
    })
    .catch((error) => {
      error.clientMessage = "No se puede crear el client";
      dispatch(actions.catchError({ error, callType: callTypes.action }));
    });
};

export const updateClient = (client) => (dispatch) => {
  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
    .updateClient(client)
    .then(() => {
      dispatch(actions.clientUpdated({ client }));
    })
    .catch((error) => {
      console.log("error", error);
      error.clientMessage = "No se puede actualizar el client";
      dispatch(actions.catchError({ error, callType: callTypes.action }));
    });
};

// export const updateProductsStatus = (ids, status) => dispatch => {
//   dispatch(actions.startCall({ callType: callTypes.action }));
//   return requestFromServer
//     .updateStatusForProducts(ids, status)
//     .then(() => {
//       dispatch(actions.productsStatusUpdated({ ids, status }));
//     })
//     .catch(error => {
//       error.clientMessage = "Can't update products status";
//       dispatch(actions.catchError({ error, callType: callTypes.action }));
//     });
// };

// export const deleteProducts = ids => dispatch => {
//   dispatch(actions.startCall({ callType: callTypes.action }));
//   return requestFromServer
//     .deleteProducts(ids)
//     .then(() => {
//       dispatch(actions.productsDeleted({ ids }));
//     })
//     .catch(error => {
//       error.clientMessage = "Can't delete products";
//       dispatch(actions.catchError({ error, callType: callTypes.action }));
//     });
// };

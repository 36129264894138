// Form is based on Formik
// Data validation is based on Yup
// Please, be familiar with article first:
// https://hackernoon.com/react-form-validation-with-formik-and-yup-8b76bda62e10
import React from "react";
import { Formik, Form, Field } from "formik";
import * as Yup from "yup";
import { Input } from "../../../../_metronic/_partials/controls";

// Validation schema
const ProgramModuleEditSchema = (id) => {
  return Yup.object().shape({
    position: Yup.number().required("Se requiere una posición"),
    duration: Yup.number().required("Se requiere una duración"),
  });
};

export function ProgramModuleEditForm({
  programModule,
  btnRef,
  saveProgramModule,
}) {
  return (
    <>
      <Formik
        enableReinitialize={true}
        initialValues={programModule}
        validationSchema={ProgramModuleEditSchema(programModule.id)}
        onSubmit={(values) => {
          saveProgramModule(values);
        }}
      >
        {({ values, handleSubmit, setFieldValue, errors }) => (
          <>
            <Form className="form form-label-right">
              <div className="form-group row">
                <div className="col-lg-6">
                  <Field
                    name="position"
                    component={Input}
                    placeholder="Posición"
                    label="Posición"
                  />
                </div>
                <div className="col-lg-6">
                  <Field
                    name="duration"
                    component={Input}
                    placeholder="Duración"
                    label="Duración"
                  />
                </div>
              </div>
              <button
                type="submit"
                style={{ display: "none" }}
                ref={btnRef}
                onSubmit={() => handleSubmit()}
              ></button>
            </Form>
          </>
        )}
      </Formik>
    </>
  );
}

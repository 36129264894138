import React from "react";
import { Card } from "react-bootstrap";

export function WebsiteDashboard() {
  return (
    <Card>
      <Card.Header>
        <h3>Bienvenido</h3>
      </Card.Header>
      <Card.Body>
        <Card.Text>
          Gracias por utilizar nuestro sistema, nuestros sistemas han sido un
          gran éxito entre nuestros clientes ya que de manera eficiente y
          segura, podra utilizar su sistema creado totalmente a medida contando
          con los requerimientos especificos que usted nos ha proporcionado.
        </Card.Text>
        <Card.Text>
          Cualquier duda respecto al funcionamiento o algún error favor de
          enviar correo electrónico a contacto@designingstyle.com.mx.
        </Card.Text>
      </Card.Body>
    </Card>
  );
}

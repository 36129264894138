import { createSlice } from "@reduxjs/toolkit";
import { validErrors } from "../../../functions";

const initialProgramsState = {
  listLoading: false,
  actionsLoading: false,
  totalCount: 0,
  entities: null,
  programForEdit: undefined,
  lastError: null,
};
export const callTypes = {
  list: "list",
  action: "action",
};

export const programsSlice = createSlice({
  name: "programs",
  initialState: initialProgramsState,
  reducers: {
    catchError: (state, action) => {
      // const errorResponse = action.payload.error.response.data[0];
      // console.log(action.payload);

      state.error = `${action.type}: ${action.payload.error}`;
      if (action.payload.callType === callTypes.list) {
        state.listLoading = false;
      } else {
        state.actionsLoading = false;
      }
      validErrors(
        action.payload.error.response,
        "Existen modulos con este programa."
      );
    },
    startCall: (state, action) => {
      state.error = null;
      if (action.payload.callType === callTypes.list) {
        state.listLoading = true;
      } else {
        state.actionsLoading = true;
      }
    },
    // getProgramsById
    programFetched: (state, action) => {
      state.actionsLoading = false;
      state.programForEdit = action.payload.programForEdit;
      state.error = null;
    },
    // findPrograms
    programsFetched: (state, action) => {
      const { count, data } = action.payload;
      state.listLoading = false;
      state.error = null;
      state.entities = data;
      state.totalCount = count;
    },
    // createProgram
    programCreated: (state, action) => {
      state.actionsLoading = false;
      state.error = null;
      state.entities.push(action.payload.program);
    },
    // updateProgram
    programUpdated: (state, action) => {
      state.error = null;
      state.actionsLoading = false;
      state.entities = state.entities.map((entity) => {
        if (entity.id === action.payload.program.id) {
          return action.payload.program;
        }
        return entity;
      });
    },
    // deleteProgram
    programDeleted: (state, action) => {
      state.error = null;
      state.actionsLoading = false;
      state.entities = state.entities.filter(
        (el) => el.id !== action.payload.id
      );
    },
    // deletePrograms
    programsDeleted: (state, action) => {
      state.error = null;
      state.actionsLoading = false;
      state.entities = state.entities.filter(
        (el) => !action.payload.ids.includes(el.id)
      );
    },
    // programsUpdateState
    programsStatusUpdated: (state, action) => {
      state.actionsLoading = false;
      state.error = null;
      const { ids, status } = action.payload;
      state.entities = state.entities.map((entity) => {
        if (ids.findIndex((id) => id === entity.id) > -1) {
          entity.status = status;
        }
        return entity;
      });
    },
  },
});

// React bootstrap table next =>
// DOCS: https://react-bootstrap-table.github.io/react-bootstrap-table2/docs/
// STORYBOOK: https://react-bootstrap-table.github.io/react-bootstrap-table2/storybook/index.html
import React, { useEffect, useMemo } from "react";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory, {
  PaginationProvider,
} from "react-bootstrap-table2-paginator";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import * as actions from "../_redux/blogsActions";
import * as uiHelpers from "../BlogsUIHelpers";
import {
  getHandlerTableChange,
  NoRecordsFoundMessage,
  PleaseWaitMessage,
  sortCaret,
} from "../../../../_metronic/_helpers";
import * as columnFormatters from "./column-formatters";
import { Pagination } from "../../../../_metronic/_partials/controls";
import { useBlogsUIContext } from "../BlogsUIContext";

export function BlogsTable() {
  // console.log(history);
  // Blogs UI Context
  const blogsUIContext = useBlogsUIContext();
  const blogsUIProps = useMemo(() => {
    return {
      ids: blogsUIContext.ids,
      setIds: blogsUIContext.setIds,
      queryParams: blogsUIContext.queryParams,
      setQueryParams: blogsUIContext.setQueryParams,
      openEditBlogPage: blogsUIContext.openEditBlogPage,
      openDeleteBlogDialog: blogsUIContext.openDeleteBlogDialog,
    };
  }, [blogsUIContext]);

  // Getting curret state of blogs list from store (Redux)
  const { currentState } = useSelector(
    (state) => ({ currentState: state.blogs }),
    shallowEqual
  );
  const { totalCount, entities, listLoading } = currentState;
  // Blogs Redux state
  const dispatch = useDispatch();
  useEffect(() => {
    // clear selections list
    blogsUIProps.setIds([]);
    // server call by queryParams
    dispatch(actions.fetchBlogs(blogsUIProps.queryParams));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [blogsUIProps.queryParams, dispatch]);
  // Table columns
  const columns = [
    {
      dataField: "title",
      text: "Titulo",
      sort: true,
      sortCaret: sortCaret,
    },
    {
      dataField: "full_date",
      text: "Fecha",
      // sort: true,
      // sortCaret: sortCaret,
    },
    {
      dataField: "short_description",
      text: "Descripción Corta",
      sort: true,
      sortCaret: sortCaret,
      formatter: columnFormatters.DescriptionColumnFormatter,
    },
    {
      dataField: "description",
      text: "Descripción",
      sort: true,
      sortCaret: sortCaret,
      formatter: columnFormatters.DescriptionColumnFormatter,
    },
    {
      dataField: "image",
      text: "Imagen",
      formatter: columnFormatters.ImageColumnFormatter,
    },
    {
      dataField: "action",
      text: "Opciones",
      formatter: columnFormatters.ActionsColumnFormatter,
      formatExtraData: {
        openEditBlogPage: blogsUIProps.openEditBlogPage,
        openDeleteBlogDialog: blogsUIProps.openDeleteBlogDialog,
      },
      classes: "text-right pr-0",
      headerClasses: "text-right pr-3",
      style: {
        minWidth: "100px",
      },
    },
  ];

  const customTotal = (from, to, size) => (
    <span className="react-bootstrap-table-pagination-total">
      Mostrando {from} a {to} de {size} Registros
    </span>
  );

  // Table pagination properties
  const paginationOptions = {
    custom: true,
    totalSize: totalCount,
    sizePerPageList: uiHelpers.sizePerPageList,
    sizePerPage: blogsUIProps.queryParams.pageSize,
    page: blogsUIProps.queryParams.pageNumber,
    paginationTotalRenderer: customTotal,
  };

  const entitiesClean = (entities || []).filter(function(element) {
    return element !== undefined;
  });
  return (
    <>
      <PaginationProvider pagination={paginationFactory(paginationOptions)}>
        {({ paginationProps, paginationTableProps }) => {
          return (
            <Pagination
              isLoading={listLoading}
              paginationProps={paginationProps}
            >
              <BootstrapTable
                wrapperClasses="table-responsive"
                classes="table table-head-custom table-vertical-center overflow-hidden"
                bootstrap4
                bordered={false}
                remote
                keyField="id"
                data={entitiesClean === null ? [] : entitiesClean}
                columns={columns}
                defaultSorted={uiHelpers.defaultSorted}
                onTableChange={getHandlerTableChange(
                  blogsUIProps.setQueryParams
                )}
                // selectRow={getSelectRow({
                //   entities,
                //   ids: blogsUIProps.ids,
                //   setIds: blogsUIProps.setIds,
                // })}
                {...paginationTableProps}
              >
                <PleaseWaitMessage entities={entitiesClean} />
                <NoRecordsFoundMessage entities={entitiesClean} />
              </BootstrapTable>
            </Pagination>
          );
        }}
      </PaginationProvider>
    </>
  );
}

import * as requestFromServer from "./modulesDetailCrud";
import {modulesDetailSlice, callTypes} from "./modulesDetailSlice";

const {actions} = modulesDetailSlice;

export const fetchModulesDetails = (module, queryParams) => dispatch => {
  dispatch(actions.startCall({ callType: callTypes.list }));
  return requestFromServer
    .findModulesDetail(module, queryParams)
    .then(response => {
      const { count, data } = response.data;
      dispatch(actions.modulesDetailsFetched({ count, data }));
    })
    .catch(error => {
      error.clientMessage = "Can't find details";
      dispatch(actions.catchError({ error, callType: callTypes.list }));
    });
};

export const fetchModulesDetail = (id) => dispatch => {
  if (!id) {
    return dispatch(actions.modulesDetailFetched({ modulesDetailForEdit: undefined }));
  }

  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
    .getModulesDetailById(id)
    .then(response => {
      const module = response.data;
      dispatch(actions.modulesDetailFetched({ modulesDetailForEdit: module }));
    })
    .catch(error => {
      error.clientMessage = "No puedo encontrar el detail";
      dispatch(actions.catchError({ error, callType: callTypes.action }));
    });
};

export const deleteModulesDetail = (id) => dispatch => {
  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
    .deleteModulesDetail(id)
    .then(response => {
      dispatch(actions.modulesDetailDeleted({ id }));
    })
    .catch(error => {
      error.clientMessage = "Can't delete detail";
      dispatch(actions.catchError({ error, callType: callTypes.action }));
    });
};

export const createModulesDetail = (module, modulesDetailForCreation) => dispatch => {
  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
    .createModulesDetail(module, modulesDetailForCreation)
    .then(response => {
      const { module } = response.data;
      dispatch(actions.modulesDetailCreated({ module }));
    })
    .catch(error => {
      error.clientMessage = "No se puede crear el modulo";
      dispatch(actions.catchError({ error, callType: callTypes.action }));
    });
};

export const updateModulesDetail = (modules_detail) => dispatch => {
  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
    .updateModulesDetail(modules_detail)
    .then(() => {
      dispatch(actions.modulesDetailUpdated({ modules_detail }));
    })
    .catch(error => {
      console.log("error", error);
      error.clientMessage = "No se puede actualizar el modulo";
      dispatch(actions.catchError({ error, callType: callTypes.action }));
    });
};

// export const updateProductsStatus = (ids, status) => dispatch => {
//   dispatch(actions.startCall({ callType: callTypes.action }));
//   return requestFromServer
//     .updateStatusForProducts(ids, status)
//     .then(() => {
//       dispatch(actions.productsStatusUpdated({ ids, status }));
//     })
//     .catch(error => {
//       error.clientMessage = "Can't update products status";
//       dispatch(actions.catchError({ error, callType: callTypes.action }));
//     });
// };

// export const deleteProducts = ids => dispatch => {
//   dispatch(actions.startCall({ callType: callTypes.action }));
//   return requestFromServer
//     .deleteProducts(ids)
//     .then(() => {
//       dispatch(actions.productsDeleted({ ids }));
//     })
//     .catch(error => {
//       error.clientMessage = "Can't delete products";
//       dispatch(actions.catchError({ error, callType: callTypes.action }));
//     });
// };

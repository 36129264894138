import * as requestFromServer from "./programsCategoryCrud";
import {programsCategorySlice, callTypes} from "./programsCategorySlice";

const {actions} = programsCategorySlice;

export const fetchProgramsCategory = (queryParams) => dispatch => {
  dispatch(actions.startCall({ callType: callTypes.list }));
  return requestFromServer
    .findProgramsCategory(queryParams)
    .then(response => {
      const { count, data } = response.data;
      dispatch(actions.programsCategoryFetched({ count, data }));
    })
    .catch(error => {
      error.clientMessage = "Can't find programs";
      dispatch(actions.catchError({ error, callType: callTypes.list }));
    });
};

export const fetchProgramCategory = (id) => dispatch => {
  if (!id) {
    return dispatch(actions.programCategoryFetched({ programCategoryForEdit: undefined }));
  }

  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
    .getProgramCategoryById(id)
    .then(response => {
      const program = response.data;
      dispatch(actions.programCategoryFetched({ programCategoryForEdit: program }));
    })
    .catch(error => {
      error.clientMessage = "No puedo encontrar el programa";
      dispatch(actions.catchError({ error, callType: callTypes.action }));
    });
};

export const deleteProgramCategory = (id) => dispatch => {
  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
    .deleteProgramCategory(id)
    .then(response => {
      dispatch(actions.programCategoryDeleted({ id }));
    })
    .catch(error => {
      error.clientMessage = "Can't delete program";
      dispatch(actions.catchError({ error, callType: callTypes.action }));
    });
};

export const createProgramCategory = (programForCreation) => dispatch => {
  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
    .createProgramCategory(programForCreation)
    .then(response => {
      const { program } = response.data;
      dispatch(actions.programCategoryCreated({ program }));
    })
    .catch(error => {
      error.clientMessage = "No se puede crear el programa";
      dispatch(actions.catchError({ error, callType: callTypes.action }));
    });
};

export const updateProgramCategory = (programs_category) => dispatch => {
  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
    .updateProgramCategory(programs_category)
    .then(() => {
      dispatch(actions.programCategoryUpdated({ programs_category }));
    })
    .catch(error => {
      console.log("error", error);
      error.clientMessage = "No se puede actualizar el programa";
      dispatch(actions.catchError({ error, callType: callTypes.action }));
    });
};

// export const updateProductsStatus = (ids, status) => dispatch => {
//   dispatch(actions.startCall({ callType: callTypes.action }));
//   return requestFromServer
//     .updateStatusForProducts(ids, status)
//     .then(() => {
//       dispatch(actions.productsStatusUpdated({ ids, status }));
//     })
//     .catch(error => {
//       error.clientMessage = "Can't update products status";
//       dispatch(actions.catchError({ error, callType: callTypes.action }));
//     });
// };

// export const deleteProducts = ids => dispatch => {
//   dispatch(actions.startCall({ callType: callTypes.action }));
//   return requestFromServer
//     .deleteProducts(ids)
//     .then(() => {
//       dispatch(actions.productsDeleted({ ids }));
//     })
//     .catch(error => {
//       error.clientMessage = "Can't delete products";
//       dispatch(actions.catchError({ error, callType: callTypes.action }));
//     });
// };

import React from "react";
import { Route } from "react-router-dom";
import { useSubheader } from "../../../_metronic/layout";
import { SlideshowsLoadingDialog } from "./slideshows-loading-dialog/SlideshowsLoadingDialog";
import { SlideshowsUIProvider } from "./SlideshowsUIContext";
import { SlideshowDeleteDialog } from "./slideshow-delete-dialog/SlideshowDeleteDialog";
import { SlideshowsCard } from "./SlideshowsCard";

export default function SlideshowPage({ history }) {
  const suhbeader = useSubheader();
  suhbeader.setTitle("Listado Slideshows");

  const SlideshowUIEvents = {
    newSlideshowButtonClick: () => {
      history.push("/slideshow/nuevo");
    },
    openEditSlideshowPage: (id) => {
      history.push(`/slideshow/${id}/editar`);
    },
    openDeleteSlideshowDialog: (id) => {
      history.push(`/slideshow/${id}/eliminar`);
    },
  };

  return (
    <SlideshowsUIProvider slideshowsUIEvents={SlideshowUIEvents}>
      <SlideshowsLoadingDialog />
      <Route path="/slideshow/:id/eliminar">
        {({ history, match }) => (
          <SlideshowDeleteDialog
            show={match != null}
            id={match && match.params.id}
            onHide={() => {
              history.push("/slideshow/");
            }}
          />
        )}
      </Route>
      <SlideshowsCard />
    </SlideshowsUIProvider>
  );
}

// Form is based on Formik
// Data validation is based on Yup
// Please, be familiar with article first:
// https://hackernoon.com/react-form-validation-with-formik-and-yup-8b76bda62e10
import React from "react";
import { Formik, Form, Field } from "formik";
import * as Yup from "yup";
import {
  Input,
  DatePickerField,
} from "../../../../_metronic/_partials/controls";

// Validation schema
const BlogEditSchema = (id) => {
  return Yup.object().shape({
    file: !id ? Yup.mixed().required("Se requiere una imagen") : Yup.mixed(),
    title: Yup.string()
      .min(2, "Mínimo 2 caracteres")
      .max(100, "Máximo 100 caracteres")
      .required("Se requiere un título"),
    date: Yup.mixed()
      .nullable(false)
      .required("Se requiere una fecha"),
  });
};

export function BlogEditForm({ blog, btnRef, saveBlog }) {
  const validField = (value, error) => {
    if (error) {
      return "is-invalid";
    } else if (value) {
      return "is-valid";
    }
    return "";
  };

  return (
    <>
      <Formik
        enableReinitialize={true}
        initialValues={blog}
        validationSchema={BlogEditSchema(blog.id)}
        onSubmit={(values) => {
          saveBlog(values);
        }}
      >
        {({ values, handleSubmit, setFieldValue, errors }) => (
          <>
            <Form className="form form-label-right">
              <div className="form-group row">
                <div className="col-lg-6">
                  <div>
                    <label>Imagen</label>
                  </div>
                  <input
                    id="file"
                    name="file"
                    type="file"
                    // name="pic"
                    accept=".png, .jpg, .jpeg"
                    onChange={(event) => {
                      setFieldValue("file", event.currentTarget.files[0]);
                    }}
                    className={`form-control ${validField(
                      values.file,
                      errors.file
                    )}`}
                  />
                  {errors.file ? (
                    <div className="invalid-feedback">{errors.file}</div>
                  ) : null}
                </div>
                <div className="col-lg-6">
                  <Field
                    name="title"
                    component={Input}
                    placeholder="Título"
                    label="Titulo"
                  />
                </div>
              </div>
              <div className="form-group row">
                <div className="col-lg-6">
                  <DatePickerField
                    name="date"
                    label="Fecha"
                    autoComplete="off"
                  />
                </div>
              </div>
              <div className="form-group row">
                <div className="col-lg-12">
                  <label>Descripción Corta</label>
                  <Field
                    name="short_description"
                    as="textarea"
                    className="form-control"
                    placeholder="Descripción Corta"
                  />
                </div>
              </div>
              <div className="form-group row">
                <div className="col-lg-12">
                  <label>Descripción</label>
                  <Field
                    name="description"
                    as="textarea"
                    className="form-control"
                    placeholder="Descripción"
                  />
                </div>
              </div>
              <button
                type="submit"
                style={{ display: "none" }}
                ref={btnRef}
                onSubmit={() => handleSubmit()}
              ></button>
            </Form>
          </>
        )}
      </Formik>
    </>
  );
}

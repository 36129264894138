import * as requestFromServer from "./slideshowsCrud";
import {slideshowsSlice, callTypes} from "./slideshowsSlice";

const {actions} = slideshowsSlice;

export const validResponse = (error, history) => {
  const errorResponse = error.response.data[0];
  if(errorResponse && errorResponse === "Expired token") {
    history.push("/logout");
  }
}

export const fetchSlideshows = (queryParams) => dispatch => {
  dispatch(actions.startCall({ callType: callTypes.list }));
  return requestFromServer
    .findSlideshows(queryParams)
    .then(response => {
      const { count, data } = response.data;
      dispatch(actions.slideshowsFetched({ count, data }));
    })
    .catch(error => {
      error.clientMessage = "Can't find slideshows";
      // validResponse(error, history)
      dispatch(actions.catchError({ error, callType: callTypes.list }));
    });
};

export const fetchSlideshow = (id) => dispatch => {
  if (!id) {
    return dispatch(actions.slideshowFetched({ slideshowForEdit: undefined }));
  }

  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
    .getSlideshowById(id)
    .then(response => {
      const slideshow = response.data;
      dispatch(actions.slideshowFetched({ slideshowForEdit: slideshow }));
    })
    .catch(error => {
      error.clientMessage = "No puedo encontrar el slideshow";
      dispatch(actions.catchError({ error, callType: callTypes.action }));
    });
};

export const deleteSlideshow = (id) => dispatch => {
  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
    .deleteSlideshow(id)
    .then(response => {
      dispatch(actions.slidshowDeleted({ id }));
    })
    .catch(error => {
      error.clientMessage = "Can't delete slideshow";
      dispatch(actions.catchError({ error, callType: callTypes.action }));
    });
};

export const createSlideshow = (slideshowForCreation) => dispatch => {
  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
    .createSlideshow(slideshowForCreation)
    .then(response => {
      const { slideshow } = response.data;
      dispatch(actions.slideshowCreated({ slideshow }));
    })
    .catch(error => {
      error.clientMessage = "No se puede crear el slideshow";
      dispatch(actions.catchError({ error, callType: callTypes.action }));
    });
};

export const updateSlideshow = (slideshow) => dispatch => {
  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
    .updateSlideshow(slideshow)
    .then(() => {
      dispatch(actions.slideshowUpdated({ slideshow }));
    })
    .catch(error => {
      console.log("error", error);
      error.clientMessage = "No se puede actualizar el slideshow";
      dispatch(actions.catchError({ error, callType: callTypes.action }));
    });
};

// export const updateProductsStatus = (ids, status) => dispatch => {
//   dispatch(actions.startCall({ callType: callTypes.action }));
//   return requestFromServer
//     .updateStatusForProducts(ids, status)
//     .then(() => {
//       dispatch(actions.productsStatusUpdated({ ids, status }));
//     })
//     .catch(error => {
//       error.clientMessage = "Can't update products status";
//       dispatch(actions.catchError({ error, callType: callTypes.action }));
//     });
// };

// export const deleteProducts = ids => dispatch => {
//   dispatch(actions.startCall({ callType: callTypes.action }));
//   return requestFromServer
//     .deleteProducts(ids)
//     .then(() => {
//       dispatch(actions.productsDeleted({ ids }));
//     })
//     .catch(error => {
//       error.clientMessage = "Can't delete products";
//       dispatch(actions.catchError({ error, callType: callTypes.action }));
//     });
// };

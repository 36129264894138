import axios from "axios";

export const ProgramStatusCssClasses = ["success", "info", ""];
export const ProgramsStatusTitles = ["Selling", "Sold"];
export const ProgramConditionCssClasses = ["success", "danger", ""];
export const ProgramConditionTitles = ["New", "Used"];
export const defaultSorted = [{ dataField: "id", order: "asc" }];
export const sizePerPageList = [
  { text: "3", value: 3 },
  { text: "5", value: 5 },
  { text: "10", value: 10 },
];
export const initialFilter = {
  searchFields: [],
  search: "",
  sortOrder: "asc", // asc||desc
  sortField: "id",
  pageNumber: 1,
  pageSize: 10,
};
export const BASE_URL = "https://api.v1.qres.com.mx";

export function categories() {
  const params = {
    order: "asc",
    limit: "none",
  };

  return axios.get(`${BASE_URL}/programs/categories`, { params });

  // console.log("response", response);

  // return [
  //   {
  //       "id": "1",
  //       "category": "Guardias"
  //   },
  //   {
  //       "id": "2",
  //       "category": "Custodios"
  //   },
  //   {
  //       "id": "3",
  //       "category": "Coordinadores y Supervisores"
  //   },
  //   {
  //       "id": "4",
  //       "category": "División Canina"
  //   }
  // ]
}

import React, { createContext, useContext, useState, useCallback } from "react";
import { isEqual, isFunction } from "lodash";
import { initialFilter } from "./ProgramsModuleUIHelpers";

const ProgramsModuleUIContext = createContext();

export function useProgramsModuleUIContext() {
  return useContext(ProgramsModuleUIContext);
}

export const ProgramsModuleUIConsumer = ProgramsModuleUIContext.Consumer;

export function ProgramsModuleUIProvider({ programsModuleUIEvents, children }) {
  const [queryParams, setQueryParamsBase] = useState(initialFilter);
  const [ids, setIds] = useState([]);
  const setQueryParams = useCallback((nextQueryParams) => {
    setQueryParamsBase((prevQueryParams) => {
      if (isFunction(nextQueryParams)) {
        nextQueryParams = nextQueryParams(prevQueryParams);
      }

      if (isEqual(prevQueryParams, nextQueryParams)) {
        return prevQueryParams;
      }

      return nextQueryParams;
    });
  }, []);

  const value = {
    queryParams,
    setQueryParamsBase,
    ids,
    setIds,
    setQueryParams,
    newProgramModuleButtonClick:
      programsModuleUIEvents.newProgramModuleButtonClick,
    openEditProgramModulePage: programsModuleUIEvents.openEditProgramModulePage,
    openDeleteProgramModuleDialog:
      programsModuleUIEvents.openDeleteProgramModuleDialog,
    openModulesDetailPage: programsModuleUIEvents.openModulesDetailPage,
  };

  return (
    <ProgramsModuleUIContext.Provider value={value}>
      {children}
    </ProgramsModuleUIContext.Provider>
  );
}

function checkParams(url) {
  if(url.includes("/?")) {
    return "&";
  }
  return "?";
}

export default function setupAxios(axios, store) {
  axios.interceptors.request.use(
    config => {
      const {
        auth: { authToken }
      } = store.getState();

      if (authToken) {
        config.url = `${config.url}${checkParams(config.url)}_token=${authToken}`;
      }

      return config;
    },
    err => Promise.reject(err)
  );
}

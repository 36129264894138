import * as requestFromServer from "./programsCrud";
import {programsSlice, callTypes} from "./programsSlice";

const {actions} = programsSlice;

export const fetchPrograms = (queryParams) => dispatch => {
  dispatch(actions.startCall({ callType: callTypes.list }));
  return requestFromServer
    .findPrograms(queryParams)
    .then(response => {
      const { count, data } = response.data;
      dispatch(actions.programsFetched({ count, data }));
    })
    .catch(error => {
      error.clientMessage = "Can't find programs";
      dispatch(actions.catchError({ error, callType: callTypes.list }));
    });
};

export const fetchProgram = (id) => dispatch => {
  if (!id) {
    return dispatch(actions.programFetched({ programForEdit: undefined }));
  }

  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
    .getProgramById(id)
    .then(response => {
      const program = response.data;
      dispatch(actions.programFetched({ programForEdit: program }));
    })
    .catch(error => {
      error.clientMessage = "No puedo encontrar el programa";
      dispatch(actions.catchError({ error, callType: callTypes.action }));
    });
};

export const deleteProgram = (id) => dispatch => {
  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
    .deleteProgram(id)
    .then(response => {
      dispatch(actions.programDeleted({ id }));
    })
    .catch(error => {
      error.clientMessage = "Can't delete program";
      dispatch(actions.catchError({ error, callType: callTypes.action }));
    });
};

export const createProgram = (programForCreation) => dispatch => {
  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
    .createProgram(programForCreation)
    .then(response => {
      const { program } = response.data;
      dispatch(actions.programCreated({ program }));
    })
    .catch(error => {
      error.clientMessage = "No se puede crear el programa";
      dispatch(actions.catchError({ error, callType: callTypes.action }));
    });
};

export const updateProgram = (program) => dispatch => {
  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
    .updateProgram(program)
    .then(() => {
      dispatch(actions.programUpdated({ program }));
    })
    .catch(error => {
      console.log("error", error);
      error.clientMessage = "No se puede actualizar el programa";
      dispatch(actions.catchError({ error, callType: callTypes.action }));
    });
};

// export const updateProductsStatus = (ids, status) => dispatch => {
//   dispatch(actions.startCall({ callType: callTypes.action }));
//   return requestFromServer
//     .updateStatusForProducts(ids, status)
//     .then(() => {
//       dispatch(actions.productsStatusUpdated({ ids, status }));
//     })
//     .catch(error => {
//       error.clientMessage = "Can't update products status";
//       dispatch(actions.catchError({ error, callType: callTypes.action }));
//     });
// };

// export const deleteProducts = ids => dispatch => {
//   dispatch(actions.startCall({ callType: callTypes.action }));
//   return requestFromServer
//     .deleteProducts(ids)
//     .then(() => {
//       dispatch(actions.productsDeleted({ ids }));
//     })
//     .catch(error => {
//       error.clientMessage = "Can't delete products";
//       dispatch(actions.catchError({ error, callType: callTypes.action }));
//     });
// };

import axios from "axios";

export const BASE_URL = "https://api.v1.qres.com.mx"
export const SLIDESHOW_URL = `${BASE_URL}/slideshow`;
export const SLIDESHOWS_URL = `${BASE_URL}/slideshows`;

// CREATE =>  POST: add a new slideshow to the server
export function createSlideshow(slideshow) {
  return axios.post(SLIDESHOW_URL, slideshow, {
    headers: {
      'Content-Type': 'multipart/form-data'
    }
  });
}

// READ
export function getAllSlideshows() {
  return axios.get(SLIDESHOWS_URL);
}

export function getSlideshowById(slideshowId) {
  return axios.get(`${SLIDESHOW_URL}/${slideshowId}`);
}

// Method from server should return QueryResultsModel(items: any[], totalsCount: number)
// items => filtered/sorted result
export function findSlideshows(qp) {
  // console.log("queryParams", qp);
  const params = {
    offset: qp.pageNumber - 1,
    limit: qp.pageSize,
    field: qp.sortField,
    order: qp.sortOrder,
    search_fields: qp.searchFields.join(','),
    search: qp.search
  }
  // console.log("params", params);
  // const response = axios.get(`${SLIDESHOWS_URL}`, { queryParams });
  const response = axios.get(`${SLIDESHOWS_URL}`, { params });
  // console.log(response);
  return response;
}

// UPDATE => PUT: update the slideshow on the server
export function updateSlideshow(slideshow) {
  const id = slideshow.get("id");
  return axios.post(`${SLIDESHOW_URL}/${id}`, slideshow, {
    headers: {
      'Content-Type': 'multipart/form-data'
    }
  });
}

// DELETE => delete the product from the server
export function deleteSlideshow(slideshowId) {
  return axios.delete(`${SLIDESHOW_URL}/${slideshowId}`);
}

// // DELETE Products by ids
// export function deleteProducts(ids) {
//   return axios.post(`${PRODUCTS_URL}/deleteProducts`, { ids });
// }

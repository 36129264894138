import React, { useMemo } from "react";
import { useProgramsCategoryUIContext } from "../ProgramsCategoryUIContext";

export function ProgramsCategoryGrouping() {
  // Programs UI Context
  const programsCategoryUIContext = useProgramsCategoryUIContext();
  const programsCategoryUIProps = useMemo(() => {
    return {
      ids: programsCategoryUIContext.ids,
      setIds: programsCategoryUIContext.setIds,
      openDeleteProgramsCategoryDialog:
        programsCategoryUIContext.openDeleteProductsCategoryDialog,
      openFetchProgramsCategoryDialog:
        programsCategoryUIContext.openFetchProductsCategoryDialog,
      openUpdateProgramsCategoryStatusDialog:
        programsCategoryUIContext.openUpdateProgramsCategoryStatusDialog,
    };
  }, [programsCategoryUIContext]);

  return (
    <div className="form">
      <div className="row align-items-center form-group-actions margin-top-20 margin-bottom-20">
        <div className="col-xl-12">
          <div className="form-group form-group-inline">
            <div className="form-label form-label-no-wrap">
              <label className="-font-bold font-danger-">
                <span>
                  Selected records count:{" "}
                  <b>{programsCategoryUIProps.ids.length}</b>
                </span>
              </label>
            </div>
            <div>
              <button
                type="button"
                className="btn btn-danger font-weight-bolder font-size-sm"
                onClick={
                  programsCategoryUIProps.openDeleteProgramsCategoryDialog
                }
              >
                <i className="fa fa-trash"></i> Delete All
              </button>
              &nbsp;
              <button
                type="button"
                className="btn btn-light-primary font-weight-bolder font-size-sm"
                onClick={
                  programsCategoryUIProps.openFetchProgramsCategoryDialog
                }
              >
                <i className="fa fa-stream"></i> Fetch Selected
              </button>
              &nbsp;
              <button
                type="button"
                className="btn btn-light-primary font-weight-bolder font-size-sm"
                onClick={
                  programsCategoryUIProps.openUpdateProgramsCategoryStatusDialog
                }
              >
                <i className="fa fa-sync-alt"></i> Update Status
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

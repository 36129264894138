import React from "react";
import { Route } from "react-router-dom";
import { useSubheader } from "../../../_metronic/layout";
import { BlogsLoadingDialog } from "./blogs-loading-dialog/BlogsLoadingDialog";
import { BlogsUIProvider } from "./BlogsUIContext";
import { BlogDeleteDialog } from "./blog-delete-dialog/BlogDeleteDialog";
import { BlogsCard } from "./BlogsCard";

export default function BlogPage({ history }) {
  const suhbeader = useSubheader();
  suhbeader.setTitle("Listado Noticias");

  const BlogUIEvents = {
    newBlogButtonClick: () => {
      history.push("/blog/nuevo");
    },
    openEditBlogPage: (id) => {
      history.push(`/blog/${id}/editar`);
    },
    openDeleteBlogDialog: (id) => {
      history.push(`/blog/${id}/eliminar`);
    },
  };

  return (
    <BlogsUIProvider blogsUIEvents={BlogUIEvents}>
      <BlogsLoadingDialog />
      <Route path="/blog/:id/eliminar">
        {({ history, match }) => (
          <BlogDeleteDialog
            show={match != null}
            id={match && match.params.id}
            onHide={() => {
              history.push("/blog/");
            }}
          />
        )}
      </Route>
      <BlogsCard />
    </BlogsUIProvider>
  );
}

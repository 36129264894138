import React, {useMemo} from "react";
import objectPath from "object-path";
import {useHtmlClassService} from "../../layout";
import { WebsiteDashboard } from './WebsiteDashboard';

export function Dashboard() {
    const uiService = useHtmlClassService();
    const layoutProps = useMemo(() => {
        return {
            type: objectPath.get(
                uiService.config,
                "type"
            )};
    }, [uiService]);
    return <>
        {layoutProps.type === 'website' && <WebsiteDashboard />}
    </>;
}

/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid,jsx-a11y/role-supports-aria-props */
import React, { useEffect, useState, useRef } from "react";
import { useDispatch } from "react-redux";
import { shallowEqual, useSelector } from "react-redux";
import * as actions from "../_redux/programsActions";
import {
  Card,
  CardBody,
  CardHeader,
  CardHeaderToolbar,
} from "../../../../_metronic/_partials/controls";
import { ProgramEditForm } from "./ProgramEditForm";
import { useSubheader } from "../../../../_metronic/layout";
import { ModalProgressBar } from "../../../../_metronic/_partials/controls";
import moment from "moment";

const initProgram = {
  id: undefined,
  file: null,
  program_category_id: "",
  title: "",
  price: 0,
  description: "",
  short_description: "",
  date: "", //moment().format("DD-MM-YYYY"),
  place: "",
};

export function ProgramEdit({
  history,
  match: {
    params: { id },
  },
}) {
  // Subheader
  const suhbeader = useSubheader();

  // Tabs
  // const [tab, setTab] = useState("basic");
  const [title, setTitle] = useState("");
  const dispatch = useDispatch();
  // const layoutDispatch = useContext(LayoutContext.Dispatch);
  const { actionsLoading, programForEdit } = useSelector(
    (state) => ({
      actionsLoading: state.programs.actionsLoading,
      programForEdit: state.programs.programForEdit,
    }),
    shallowEqual
  );

  useEffect(() => {
    dispatch(actions.fetchProgram(id));
  }, [id, history, dispatch]);

  useEffect(() => {
    let _title = id ? "" : "Nuevo Programa";

    if (programForEdit && id) {
      _title = "Editar Programa";
    }

    setTitle(_title);
    suhbeader.setTitle(_title);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [programForEdit, id]);

  const saveProgram = (values) => {
    // console.log("data3", values);
    let formData = new FormData();
    formData.append("program_category_id", values.program_category_id);
    formData.append("title", values.title);
    formData.append("price", values.price);
    formData.append("description", values.description);
    formData.append("short_description", values.short_description);
    formData.append("date", moment(values.date).format("YYYY-MM-DD"));
    formData.append("place", values.place);
    if (values.file) {
      formData.append("image", values.file);
    }

    if (!id) {
      dispatch(actions.createProgram(formData)).then(() =>
        backToProgramsList()
      );
    } else {
      formData.append("id", values.id);
      dispatch(actions.updateProgram(formData)).then(() =>
        backToProgramsList()
      );
    }
  };

  const btnRef = useRef();
  const saveProgramClick = () => {
    if (btnRef && btnRef.current) {
      btnRef.current.click();
    }
  };

  const backToProgramsList = () => {
    history.push(`/programa/listado`);
  };

  return (
    <Card>
      {actionsLoading && <ModalProgressBar />}
      <CardHeader title={title}>
        <CardHeaderToolbar>
          <button
            type="button"
            onClick={backToProgramsList}
            className="btn btn-light"
          >
            <i className="fa fa-arrow-left"></i>
            Regresar
          </button>
          {`  `}
          <button
            type="submit"
            className="btn btn-primary ml-2"
            onClick={saveProgramClick}
          >
            Guardar
          </button>
        </CardHeaderToolbar>
      </CardHeader>
      <CardBody>
        <div className="mt-5">
          {/* {tab === "basic" && ( */}
          <ProgramEditForm
            actionsLoading={actionsLoading}
            program={programForEdit || initProgram}
            btnRef={btnRef}
            saveProgram={saveProgram}
          />
        </div>
      </CardBody>
    </Card>
  );
}

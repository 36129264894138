import React from "react";
import { Route } from "react-router-dom";
import { useSubheader } from "../../../_metronic/layout";
import { ProgramsModuleLoadingDialog } from "./programs-module-loading-dialog/ProgramsModuleLoadingDialog";
import { ProgramsModuleUIProvider } from "./ProgramsModuleUIContext";
import { ProgramModuleDeleteDialog } from "./program-module-delete-dialog/ProgramModuleDeleteDialog";
import { ProgramsModuleCard } from "./ProgramsModuleCard";

export default function ProgramModulePage({
  history,
  match: {
    params: { program },
  },
}) {
  const suhbeader = useSubheader();
  suhbeader.setTitle("Listado Modulo de Programa");
  const ProgramModuleUIEvents = {
    newProgramModuleButtonClick: (program) => {
      console.log(program);
      history.push(`/programa/modulo/${program}/nuevo`);
    },
    openEditProgramModulePage: (program, id) => {
      history.push(`/programa/modulo/${program}/${id}/editar`);
    },
    openDeleteProgramModuleDialog: (program, id) => {
      history.push(`/programa/modulo/${program}/${id}/eliminar`);
    },
    openModulesDetailPage: (program, id) => {
      history.push(`/programa/modulo/detalle/${program}`);
    },
  };

  return (
    <ProgramsModuleUIProvider programsModuleUIEvents={ProgramModuleUIEvents}>
      <ProgramsModuleLoadingDialog />
      <Route path="/programa/modulo/:program/:id/eliminar">
        {({ history, match }) => {
          return (
            <ProgramModuleDeleteDialog
              show={match != null}
              id={match && match.params.id}
              onHide={() => {
                history.push(`/programa/modulo/${program}`);
              }}
              program={program}
            />
          );
        }}
      </Route>
      <ProgramsModuleCard program={program} />
    </ProgramsModuleUIProvider>
  );
}

import React from "react";
import {
  ProgramCategoryStatusCssClasses,
  ProgramsCategoryStatusTitles
} from "../../ProgramsCategoryUIHelpers";

export const StatusColumnFormatter = (cellContent, row) => (
  <span
    className={`label label-lg label-light-${
      ProgramCategoryStatusCssClasses[row.status]
    } label-inline`}
  >
    {ProgramsCategoryStatusTitles[row.status]}
  </span>
);

import React from "react";
import { Route } from "react-router-dom";
import { useSubheader } from "../../../_metronic/layout";
import { ClientsLoadingDialog } from "./clients-loading-dialog/ClientsLoadingDialog";
import { ClientsUIProvider } from "./ClientsUIContext";
import { ClientDeleteDialog } from "./client-delete-dialog/ClientDeleteDialog";
import { ClientsCard } from "./ClientsCard";

export default function ClientPage({ history }) {
  const suhbeader = useSubheader();
  suhbeader.setTitle("Listado Noticias");

  const ClientUIEvents = {
    newClientButtonClick: () => {
      history.push("/cliente/nuevo");
    },
    openEditClientPage: (id) => {
      history.push(`/cliente/${id}/editar`);
    },
    openDeleteClientDialog: (id) => {
      history.push(`/cliente/${id}/eliminar`);
    },
  };

  return (
    <ClientsUIProvider clientsUIEvents={ClientUIEvents}>
      <ClientsLoadingDialog />
      <Route path="/cliente/:id/eliminar">
        {({ history, match }) => (
          <ClientDeleteDialog
            show={match != null}
            id={match && match.params.id}
            onHide={() => {
              history.push("/cliente/");
            }}
          />
        )}
      </Route>
      <ClientsCard />
    </ClientsUIProvider>
  );
}

/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid,jsx-a11y/role-supports-aria-props */
import React, { useEffect, useState, useRef } from "react";
import { useDispatch } from "react-redux";
import { shallowEqual, useSelector } from "react-redux";
import * as actions from "../_redux/programsModuleActions";
import {
  Card,
  CardBody,
  CardHeader,
  CardHeaderToolbar,
} from "../../../../_metronic/_partials/controls";
import { ProgramModuleEditForm } from "./ProgramModuleEditForm";
import { useSubheader } from "../../../../_metronic/layout";
import { ModalProgressBar } from "../../../../_metronic/_partials/controls";

const initProgram = {
  id: undefined,
  position: "",
  duration: 0,
};

export function ProgramModuleEdit({
  history,
  match: {
    params: { program, id },
  },
}) {
  // Subheader
  const suhbeader = useSubheader();

  // Tabs
  // const [tab, setTab] = useState("basic");
  const [title, setTitle] = useState("");
  const dispatch = useDispatch();
  // const layoutDispatch = useContext(LayoutContext.Dispatch);
  const { actionsLoading, programModuleForEdit } = useSelector(
    (state) => ({
      actionsLoading: state.programs_module.actionsLoading,
      programModuleForEdit: state.programs_module.programModuleForEdit,
    }),
    shallowEqual
  );

  useEffect(() => {
    dispatch(actions.fetchProgramModule(id));
  }, [id, history, dispatch]);

  useEffect(() => {
    let _title = id ? "" : "Nueva Modulo de Programa";

    if (programModuleForEdit && id) {
      _title = "Editar Modulo de Programa";
    }

    setTitle(_title);
    suhbeader.setTitle(_title);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [programModuleForEdit, id]);

  const saveProgramModule = (values) => {
    // console.log("data3", values);
    let formData = new FormData();
    formData.append("position", values.position);
    formData.append("duration", values.duration);

    if (!id) {
      dispatch(actions.createProgramModule(program, formData)).then(() =>
        backToProgramsModuleList(program)
      );
    } else {
      formData.append("id", values.id);
      dispatch(actions.updateProgramModule(formData)).then(() =>
        backToProgramsModuleList(program)
      );
    }
  };

  const btnRef = useRef();
  const saveProgramModuleClick = () => {
    if (btnRef && btnRef.current) {
      btnRef.current.click();
    }
  };

  const backToProgramsModuleList = (program) => {
    history.push(`/programa/modulo/${program}`);
  };

  return (
    <Card>
      {actionsLoading && <ModalProgressBar />}
      <CardHeader title={title}>
        <CardHeaderToolbar>
          <button
            type="button"
            onClick={() => backToProgramsModuleList(program)}
            className="btn btn-light"
          >
            <i className="fa fa-arrow-left"></i>
            Regresar
          </button>
          {`  `}
          <button
            type="submit"
            className="btn btn-primary ml-2"
            onClick={saveProgramModuleClick}
          >
            Guardar
          </button>
        </CardHeaderToolbar>
      </CardHeader>
      <CardBody>
        <div className="mt-5">
          {/* {tab === "basic" && ( */}
          <ProgramModuleEditForm
            actionsLoading={actionsLoading}
            programModule={programModuleForEdit || initProgram}
            btnRef={btnRef}
            saveProgramModule={saveProgramModule}
          />
        </div>
      </CardBody>
    </Card>
  );
}

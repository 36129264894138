import React, { useMemo } from "react";
import { useProgramsModuleUIContext } from "../ProgramsModuleUIContext";

export function ProgramsModuleGrouping() {
  // Programs UI Context
  const programsModuleUIContext = useProgramsModuleUIContext();
  const programsModuleUIProps = useMemo(() => {
    return {
      ids: programsModuleUIContext.ids,
      setIds: programsModuleUIContext.setIds,
      openDeleteProgramsModuleDialog:
        programsModuleUIContext.openDeleteProductsModuleDialog,
      openFetchProgramsModuleDialog:
        programsModuleUIContext.openFetchProductsModuleDialog,
      openUpdateProgramsModuleStatusDialog:
        programsModuleUIContext.openUpdateProgramsModuleStatusDialog,
    };
  }, [programsModuleUIContext]);

  return (
    <div className="form">
      <div className="row align-items-center form-group-actions margin-top-20 margin-bottom-20">
        <div className="col-xl-12">
          <div className="form-group form-group-inline">
            <div className="form-label form-label-no-wrap">
              <label className="-font-bold font-danger-">
                <span>
                  Selected records count:{" "}
                  <b>{programsModuleUIProps.ids.length}</b>
                </span>
              </label>
            </div>
            <div>
              <button
                type="button"
                className="btn btn-danger font-weight-bolder font-size-sm"
                onClick={programsModuleUIProps.openDeleteProgramsModuleDialog}
              >
                <i className="fa fa-trash"></i> Delete All
              </button>
              &nbsp;
              <button
                type="button"
                className="btn btn-light-primary font-weight-bolder font-size-sm"
                onClick={programsModuleUIProps.openFetchProgramsModuleDialog}
              >
                <i className="fa fa-stream"></i> Fetch Selected
              </button>
              &nbsp;
              <button
                type="button"
                className="btn btn-light-primary font-weight-bolder font-size-sm"
                onClick={
                  programsModuleUIProps.openUpdateProgramsModuleStatusDialog
                }
              >
                <i className="fa fa-sync-alt"></i> Update Status
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

import React from "react";
import {
  ProgramModuleStatusCssClasses,
  ProgramsModuleStatusTitles
} from "../../ProgramsModuleUIHelpers";

export const StatusColumnFormatter = (cellContent, row) => (
  <span
    className={`label label-lg label-light-${
      ProgramModuleStatusCssClasses[row.status]
    } label-inline`}
  >
    {ProgramsModuleStatusTitles[row.status]}
  </span>
);

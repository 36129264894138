import axios from "axios";

export const BASE_URL = "https://api.v1.qres.com.mx"
export const CLIENT_URL = `${BASE_URL}/client`;
export const CLIENTS_URL = `${BASE_URL}/clients`;

export const getNameField = (field) => {
  switch (field) {
    case 'full_date':
      return "date"
    default:
      return field
  }
}

// CREATE =>  POST: add a client client to the server
export function createClient(client) {
  return axios.post(CLIENT_URL, client, {
    headers: {
      'Content-Type': 'multipart/form-data'
    }
  });
}

// READ
export function getAllClients() {
  return axios.get(CLIENTS_URL);
}

export function getClientById(clientId) {
  return axios.get(`${CLIENT_URL}/${clientId}`);
}

// Method from server should return QueryResultsModel(items: any[], totalsCount: number)
// items => filtered/sorted result
export function findClients(qp) {
  // console.log("queryParams", qp);
  const params = {
    offset: qp.pageNumber - 1,
    limit: qp.pageSize,
    field: getNameField(qp.sortField),
    order: qp.sortOrder,
    search_fields: qp.searchFields.join(','),
    search: qp.search
  }
  // console.log("params", params);
  // const response = axios.get(`${CLIENTS_URL}`, { queryParams });
  const response = axios.get(`${CLIENTS_URL}`, { params });
  // console.log(response);
  return response;
}

// UPDATE => PUT: update the client on the server
export function updateClient(client) {
  const id = client.get("id");
  return axios.post(`${CLIENT_URL}/${id}`, client, {
    headers: {
      'Content-Type': 'multipart/form-data'
    }
  });
}

// DELETE => delete the product from the server
export function deleteClient(clientId) {
  return axios.delete(`${CLIENT_URL}/${clientId}`);
}

// // DELETE Products by ids
// export function deleteProducts(ids) {
//   return axios.post(`${PRODUCTS_URL}/deleteProducts`, { ids });
// }

/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid,jsx-a11y/role-supports-aria-props */
import React, { useEffect, useState, useRef } from "react";
import { useDispatch } from "react-redux";
import { shallowEqual, useSelector } from "react-redux";
import * as actions from "../_redux/slideshowsActions";
import {
  Card,
  CardBody,
  CardHeader,
  CardHeaderToolbar,
} from "../../../../_metronic/_partials/controls";
import { SlideshowEditForm } from "./SlideshowEditForm";
import { useSubheader } from "../../../../_metronic/layout";
import { ModalProgressBar } from "../../../../_metronic/_partials/controls";

const initSlideshow = {
  id: undefined,
  file: null,
  title: "",
  description: "",
  btn_label: "",
  btn_url: "",
};

export function SlideshowEdit({
  history,
  match: {
    params: { id },
  },
}) {
  // Subheader
  const suhbeader = useSubheader();

  // Tabs
  // const [tab, setTab] = useState("basic");
  const [title, setTitle] = useState("");
  const dispatch = useDispatch();
  // const layoutDispatch = useContext(LayoutContext.Dispatch);
  const { actionsLoading, slideshowForEdit } = useSelector(
    (state) => ({
      actionsLoading: state.slideshows.actionsLoading,
      slideshowForEdit: state.slideshows.slideshowForEdit,
    }),
    shallowEqual
  );

  useEffect(() => {
    dispatch(actions.fetchSlideshow(id));
  }, [id, history, dispatch]);

  useEffect(() => {
    let _title = id ? "" : "Nuevo Slideshow";

    if (slideshowForEdit && id) {
      _title = "Editar Slideshow";
    }

    setTitle(_title);
    suhbeader.setTitle(_title);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [slideshowForEdit, id]);

  const saveSlideshow = (values) => {
    // console.log("data3", values);
    let formData = new FormData();
    formData.append("title", values.title);
    formData.append("description", values.description);
    formData.append("btn_label", values.btn_label);
    formData.append("btn_url", values.btn_url);
    if (values.file) {
      formData.append("image", values.file);
    }

    if (!id) {
      dispatch(actions.createSlideshow(formData)).then(() =>
        backToSlideshowsList()
      );
    } else {
      formData.append("id", values.id);
      dispatch(actions.updateSlideshow(formData)).then(() =>
        backToSlideshowsList()
      );
    }
  };

  const btnRef = useRef();
  const saveProductClick = () => {
    if (btnRef && btnRef.current) {
      btnRef.current.click();
    }
  };

  const backToSlideshowsList = () => {
    history.push(`/slideshow/listado`);
  };

  return (
    <Card>
      {actionsLoading && <ModalProgressBar />}
      <CardHeader title={title}>
        <CardHeaderToolbar>
          <button
            type="button"
            onClick={backToSlideshowsList}
            className="btn btn-light"
          >
            <i className="fa fa-arrow-left"></i>
            Regresar
          </button>
          {/* {`  `}
          <button className="btn btn-light ml-2">
            <i className="fa fa-redo"></i>
            Limpiar
          </button> */}
          {`  `}
          <button
            type="submit"
            className="btn btn-primary ml-2"
            onClick={saveProductClick}
          >
            Guardar
          </button>
        </CardHeaderToolbar>
      </CardHeader>
      <CardBody>
        {/* <ul className="nav nav-tabs nav-tabs-line " role="tablist">
          <li className="nav-item" onClick={() => setTab("basic")}>
            <a
              className={`nav-link ${tab === "basic" && "active"}`}
              data-toggle="tab"
              role="tab"
              aria-selected={(tab === "basic").toString()}
            >
              Basic info
            </a>
          </li>
          {id && (
            <>
              {" "}
              <li className="nav-item" onClick={() => setTab("remarks")}>
                <a
                  className={`nav-link ${tab === "remarks" && "active"}`}
                  data-toggle="tab"
                  role="button"
                  aria-selected={(tab === "remarks").toString()}
                >
                  Product remarks
                </a>
              </li>
              <li className="nav-item" onClick={() => setTab("specs")}>
                <a
                  className={`nav-link ${tab === "specs" && "active"}`}
                  data-toggle="tab"
                  role="tab"
                  aria-selected={(tab === "specs").toString()}
                >
                  Product specifications
                </a>
              </li>
            </>
          )}
        </ul> */}
        <div className="mt-5">
          {/* {tab === "basic" && ( */}
          <SlideshowEditForm
            actionsLoading={actionsLoading}
            slideshow={slideshowForEdit || initSlideshow}
            btnRef={btnRef}
            saveSlideshow={saveSlideshow}
          />
          {/* )}
          {tab === "remarks" && id && (
            <RemarksUIProvider currentProductId={id}>
              <Remarks />
            </RemarksUIProvider>
          )}
          {tab === "specs" && id && (
            <SpecificationsUIProvider currentProductId={id}>
              <Specifications />
            </SpecificationsUIProvider>
          )} */}
        </div>
      </CardBody>
    </Card>
  );
}

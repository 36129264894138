/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid,jsx-a11y/role-supports-aria-props */
import React, { useEffect, useState, useRef } from "react";
import { useDispatch } from "react-redux";
import { shallowEqual, useSelector } from "react-redux";
import * as actions from "../_redux/programsCategoryActions";
import {
  Card,
  CardBody,
  CardHeader,
  CardHeaderToolbar,
} from "../../../../_metronic/_partials/controls";
import { ProgramCategoryEditForm } from "./ProgramCategoryEditForm";
import { useSubheader } from "../../../../_metronic/layout";
import { ModalProgressBar } from "../../../../_metronic/_partials/controls";

const initProgram = {
  id: undefined,
  category: "",
};

export function ProgramCategoryEdit({
  history,
  match: {
    params: { id },
  },
}) {
  // Subheader
  const suhbeader = useSubheader();

  // Tabs
  // const [tab, setTab] = useState("basic");
  const [title, setTitle] = useState("");
  const dispatch = useDispatch();
  // const layoutDispatch = useContext(LayoutContext.Dispatch);
  const { actionsLoading, programCategoryForEdit } = useSelector(
    (state) => ({
      actionsLoading: state.programs_category.actionsLoading,
      programCategoryForEdit: state.programs_category.programCategoryForEdit,
    }),
    shallowEqual
  );

  useEffect(() => {
    dispatch(actions.fetchProgramCategory(id));
  }, [id, history, dispatch]);

  useEffect(() => {
    let _title = id ? "" : "Nueva Categoria de Programa";

    if (programCategoryForEdit && id) {
      _title = "Editar Categoria de Programa";
    }

    setTitle(_title);
    suhbeader.setTitle(_title);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [programCategoryForEdit, id]);

  const saveProgramCategory = (values) => {
    // console.log("data3", values);
    let formData = new FormData();
    formData.append("category", values.category);

    if (!id) {
      dispatch(actions.createProgramCategory(formData)).then(() =>
        backToProgramsCategoryList()
      );
    } else {
      formData.append("id", values.id);
      dispatch(actions.updateProgramCategory(formData)).then(() =>
        backToProgramsCategoryList()
      );
    }
  };

  const btnRef = useRef();
  const saveProgramCategoryClick = () => {
    if (btnRef && btnRef.current) {
      btnRef.current.click();
    }
  };

  const backToProgramsCategoryList = () => {
    history.push(`/programa/categoria/listado`);
  };

  return (
    <Card>
      {actionsLoading && <ModalProgressBar />}
      <CardHeader title={title}>
        <CardHeaderToolbar>
          <button
            type="button"
            onClick={backToProgramsCategoryList}
            className="btn btn-light"
          >
            <i className="fa fa-arrow-left"></i>
            Regresar
          </button>
          {`  `}
          <button
            type="submit"
            className="btn btn-primary ml-2"
            onClick={saveProgramCategoryClick}
          >
            Guardar
          </button>
        </CardHeaderToolbar>
      </CardHeader>
      <CardBody>
        <div className="mt-5">
          {/* {tab === "basic" && ( */}
          <ProgramCategoryEditForm
            actionsLoading={actionsLoading}
            programCategory={programCategoryForEdit || initProgram}
            btnRef={btnRef}
            saveProgramCategory={saveProgramCategory}
          />
        </div>
      </CardBody>
    </Card>
  );
}

// Form is based on Formik
// Data validation is based on Yup
// Please, be familiar with article first:
// https://hackernoon.com/react-form-validation-with-formik-and-yup-8b76bda62e10
import React from "react";
import { Formik, Form, Field } from "formik";
import * as Yup from "yup";
import { Input } from "../../../../_metronic/_partials/controls";

// Validation schema
const ProgramCategoryEditSchema = (id) => {
  return Yup.object().shape({
    category: Yup.string()
      .min(2, "Mínimo 2 caracteres")
      .max(100, "Máximo 100 caracteres")
      .required("Se requiere un título"),
  });
};

export function ProgramCategoryEditForm({
  programCategory,
  btnRef,
  saveProgramCategory,
}) {
  return (
    <>
      <Formik
        enableReinitialize={true}
        initialValues={programCategory}
        validationSchema={ProgramCategoryEditSchema(programCategory.id)}
        onSubmit={(values) => {
          saveProgramCategory(values);
        }}
      >
        {({ values, handleSubmit, setFieldValue, errors }) => (
          <>
            <Form className="form form-label-right">
              <div className="form-group row">
                <div className="col-lg-6">
                  <Field
                    name="category"
                    component={Input}
                    placeholder="Categoria"
                    label="Categoria"
                  />
                </div>
              </div>
              <button
                type="submit"
                style={{ display: "none" }}
                ref={btnRef}
                onSubmit={() => handleSubmit()}
              ></button>
            </Form>
          </>
        )}
      </Formik>
    </>
  );
}

import React, { useMemo } from "react";
import {
  Card,
  CardBody,
  CardHeader,
  CardHeaderToolbar,
} from "../../../_metronic/_partials/controls";
import { SlideshowsFilter } from "./slideshows-filter/SlideshowsFilter";
import { SlideshowsTable } from "./slideshows-table/SlideshowsTable";
import { SlideshowsGrouping } from "./slideshows-grouping/SlideshowsGrouping";
import { useSlideshowsUIContext } from "./SlideshowsUIContext";

export function SlideshowsCard() {
  const slideshowsUIContext = useSlideshowsUIContext();
  const slideshowsUIProps = useMemo(() => {
    return {
      ids: slideshowsUIContext.ids,
      queryParams: slideshowsUIContext.queryParams,
      setQueryParams: slideshowsUIContext.setQueryParams,
      newSlideshowButtonClick: slideshowsUIContext.newSlideshowButtonClick,
      openDeleteSlideshowDialog: slideshowsUIContext.openDeleteSlideshowDialog,
      openEditSlideshowPage: slideshowsUIContext.openEditSlideshowPage,
    };
  }, [slideshowsUIContext]);

  return (
    <Card>
      <CardHeader title="Slideshows">
        <CardHeaderToolbar>
          <button
            type="button"
            className="btn btn-primary"
            onClick={slideshowsUIProps.newSlideshowButtonClick}
          >
            Nuevo Slideshow
          </button>
        </CardHeaderToolbar>
      </CardHeader>
      <CardBody>
        <SlideshowsFilter />
        {slideshowsUIProps.ids.length > 0 && (
          <>
            <SlideshowsGrouping />
          </>
        )}
        <SlideshowsTable />
      </CardBody>
    </Card>
  );
}

import React, { useMemo } from "react";
import {
  Card,
  CardBody,
  CardHeader,
  CardHeaderToolbar,
} from "../../../_metronic/_partials/controls";
import { ProgramsCategoryFilter } from "./programs-category-filter/ProgramsCategoryFilter";
import { ProgramsCategoryTable } from "./programs-category-table/ProgramsCategoryTable";
import { ProgramsCategoryGrouping } from "./programs-category-grouping/ProgramsCategoryGrouping";
import { useProgramsCategoryUIContext } from "./ProgramsCategoryUIContext";

export function ProgramsCategoryCard() {
  const programsCategoryUIContext = useProgramsCategoryUIContext();
  const programsCategoryUIProps = useMemo(() => {
    return {
      ids: programsCategoryUIContext.ids,
      queryParams: programsCategoryUIContext.queryParams,
      setQueryParams: programsCategoryUIContext.setQueryParams,
      newProgramCategoryButtonClick:
        programsCategoryUIContext.newProgramCategoryButtonClick,
      openDeleteProgramCategoryDialog:
        programsCategoryUIContext.openDeleteProgramCategoryDialog,
      openEditProgramCategoryPage:
        programsCategoryUIContext.openEditProgramCategoryPage,
    };
  }, [programsCategoryUIContext]);

  return (
    <Card>
      <CardHeader title="Categorias de Programas">
        <CardHeaderToolbar>
          <button
            type="button"
            className="btn btn-primary"
            onClick={programsCategoryUIProps.newProgramCategoryButtonClick}
          >
            Nueva Categoria
          </button>
        </CardHeaderToolbar>
      </CardHeader>
      <CardBody>
        <ProgramsCategoryFilter />
        {programsCategoryUIProps.ids.length > 0 && (
          <>
            <ProgramsCategoryGrouping />
          </>
        )}
        <ProgramsCategoryTable />
      </CardBody>
    </Card>
  );
}

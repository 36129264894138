import React from "react";
import {
  BlogStatusCssClasses,
  BlogsStatusTitles
} from "../../BlogsUIHelpers";

export const StatusColumnFormatter = (cellContent, row) => (
  <span
    className={`label label-lg label-light-${
      BlogStatusCssClasses[row.status]
    } label-inline`}
  >
    {BlogsStatusTitles[row.status]}
  </span>
);

import React, { useMemo } from "react";
import {
  Card,
  CardBody,
  CardHeader,
  CardHeaderToolbar,
} from "../../../_metronic/_partials/controls";
import { ProgramsFilter } from "./programs-filter/ProgramsFilter";
import { ProgramsTable } from "./programs-table/ProgramsTable";
import { ProgramsGrouping } from "./programs-grouping/ProgramsGrouping";
import { useProgramsUIContext } from "./ProgramsUIContext";

export function ProgramsCard() {
  const programsUIContext = useProgramsUIContext();
  const programsUIProps = useMemo(() => {
    return {
      ids: programsUIContext.ids,
      queryParams: programsUIContext.queryParams,
      setQueryParams: programsUIContext.setQueryParams,
      newProgramButtonClick: programsUIContext.newProgramButtonClick,
      openDeleteProgramDialog: programsUIContext.openDeleteProgramDialog,
      openEditProgramPage: programsUIContext.openEditProgramPage,
      openProgramModulePage: programsUIContext.openProgramModulePage,
    };
  }, [programsUIContext]);

  return (
    <Card>
      <CardHeader title="Programas">
        <CardHeaderToolbar>
          <button
            type="button"
            className="btn btn-primary"
            onClick={programsUIProps.newProgramButtonClick}
          >
            Nuevo Programa
          </button>
        </CardHeaderToolbar>
      </CardHeader>
      <CardBody>
        <ProgramsFilter />
        {programsUIProps.ids.length > 0 && (
          <>
            <ProgramsGrouping />
          </>
        )}
        <ProgramsTable />
      </CardBody>
    </Card>
  );
}

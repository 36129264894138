import React from "react";
import {
  ProgramStatusCssClasses,
  ProgramsStatusTitles
} from "../../ProgramsUIHelpers";

export const StatusColumnFormatter = (cellContent, row) => (
  <span
    className={`label label-lg label-light-${
      ProgramStatusCssClasses[row.status]
    } label-inline`}
  >
    {ProgramsStatusTitles[row.status]}
  </span>
);

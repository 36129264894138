/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */
import React, { useMemo } from "react";
import {Link} from "react-router-dom";
import {OverlayTrigger, Tooltip} from "react-bootstrap";
import { useHtmlClassService } from "../../_core/MetronicLayout";
import objectPath from "object-path";

export function StickyToolbar() {
  const uiService = useHtmlClassService();
  const layoutProps = useMemo(() => {
    return {
      viewContainerDisplay: objectPath.get(
        uiService.config,
        "extras.stickyToolbar.display"
      ),
      viewLayoutBuilderDisplay: objectPath.get(
        uiService.config,
        "extras.stickyToolbar.layoutBuilder.display"
      ),
      viewDocumentationDisplay: objectPath.get(
        uiService.config,
        "extras.stickyToolbar.documentation.display"
      ),
    };
  }, [uiService]);

  return (
    <>
      {layoutProps.viewContainerDisplay && (
        <ul className="sticky-toolbar nav flex-column pl-2 pr-2 pt-3 pb-3 mt-4">
          {layoutProps.viewLayoutBuilderDisplay && (
            <OverlayTrigger
              placement="left"
              overlay={<Tooltip id="layout-tooltip">Layout Builder</Tooltip>}
            >
              <li className="nav-item mb-2" data-placement="left">
                <Link
                  to="/builder"
                  className="btn btn-sm btn-icon btn-bg-light btn-text-primary btn-hover-primary"
                >
                  <i className="flaticon2-gear"></i>
                </Link>
              </li>
            </OverlayTrigger>
          )}
          {layoutProps.viewDocumentationDisplay && (
            <OverlayTrigger
              placement="left"
              overlay={<Tooltip id="documentations-tooltip">Documentation</Tooltip>}
            >
              <li className="nav-item mb-2" data-placement="left">
                <a
                  className="btn btn-sm btn-icon btn-bg-light btn-text-warning btn-hover-warning"
                  target="_blank"
                  rel="noopener noreferrer"
                  href="https://keenthemes.com/metronic/?page=docs&section=react-quick-start"
                >
                  <i className="flaticon2-telegram-logo"></i>
                </a>
              </li>
            </OverlayTrigger>
          )}
        </ul>
      )}
    </>
  );
}

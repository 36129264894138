import * as requestFromServer from "./blogsCrud";
import {blogsSlice, callTypes} from "./blogsSlice";

const {actions} = blogsSlice;

export const fetchBlogs = (queryParams) => dispatch => {
  dispatch(actions.startCall({ callType: callTypes.list }));
  return requestFromServer
    .findBlogs(queryParams)
    .then(response => {
      const { count, data } = response.data;
      dispatch(actions.blogsFetched({ count, data }));
    })
    .catch(error => {
      error.clientMessage = "Can't find blogs";
      // validResponse(error, history)
      dispatch(actions.catchError({ error, callType: callTypes.list }));
    });
};

export const fetchBlog = (id) => dispatch => {
  if (!id) {
    return dispatch(actions.blogFetched({ blogForEdit: undefined }));
  }

  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
    .getBlogById(id)
    .then(response => {
      const blog = response.data;
      dispatch(actions.blogFetched({ blogForEdit: blog }));
    })
    .catch(error => {
      error.clientMessage = "No puedo encontrar el blog";
      dispatch(actions.catchError({ error, callType: callTypes.action }));
    });
};

export const deleteBlog = (id) => dispatch => {
  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
    .deleteBlog(id)
    .then(response => {
      dispatch(actions.slidshowDeleted({ id }));
    })
    .catch(error => {
      error.clientMessage = "Can't delete blog";
      dispatch(actions.catchError({ error, callType: callTypes.action }));
    });
};

export const createBlog = (blogForCreation) => dispatch => {
  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
    .createBlog(blogForCreation)
    .then(response => {
      const { blog } = response.data;
      dispatch(actions.blogCreated({ blog }));
    })
    .catch(error => {
      error.clientMessage = "No se puede crear el blog";
      dispatch(actions.catchError({ error, callType: callTypes.action }));
    });
};

export const updateBlog = (blog) => dispatch => {
  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
    .updateBlog(blog)
    .then(() => {
      dispatch(actions.blogUpdated({ blog }));
    })
    .catch(error => {
      console.log("error", error);
      error.clientMessage = "No se puede actualizar el blog";
      dispatch(actions.catchError({ error, callType: callTypes.action }));
    });
};

// export const updateProductsStatus = (ids, status) => dispatch => {
//   dispatch(actions.startCall({ callType: callTypes.action }));
//   return requestFromServer
//     .updateStatusForProducts(ids, status)
//     .then(() => {
//       dispatch(actions.productsStatusUpdated({ ids, status }));
//     })
//     .catch(error => {
//       error.clientMessage = "Can't update products status";
//       dispatch(actions.catchError({ error, callType: callTypes.action }));
//     });
// };

// export const deleteProducts = ids => dispatch => {
//   dispatch(actions.startCall({ callType: callTypes.action }));
//   return requestFromServer
//     .deleteProducts(ids)
//     .then(() => {
//       dispatch(actions.productsDeleted({ ids }));
//     })
//     .catch(error => {
//       error.clientMessage = "Can't delete products";
//       dispatch(actions.catchError({ error, callType: callTypes.action }));
//     });
// };

import React from "react";
import {
  ModulesDetailStatusCssClasses,
  ModulesDetailStatusTitles
} from "../../ModulesDetailUIHelpers";

export const StatusColumnFormatter = (cellContent, row) => (
  <span
    className={`label label-lg label-light-${
      ModulesDetailStatusCssClasses[row.status]
    } label-inline`}
  >
    {ModulesDetailStatusTitles[row.status]}
  </span>
);

// Form is based on Formik
// Data validation is based on Yup
// Please, be familiar with article first:
// https://hackernoon.com/react-form-validation-with-formik-and-yup-8b76bda62e10
import React from "react";
import { Formik, Form, Field } from "formik";
import * as Yup from "yup";
import { Input } from "../../../../_metronic/_partials/controls";

// Validation schema
const ClientEditSchema = (id) => {
  return Yup.object().shape({
    file: !id
      ? Yup.mixed().required("Se requiere una imagen pequeña")
      : Yup.mixed(),
    file2: !id
      ? Yup.mixed().required("Se requiere una imagen grande")
      : Yup.mixed(),
    client: Yup.string()
      .min(2, "Mínimo 2 caracteres")
      .max(100, "Máximo 100 caracteres")
      .required("Se requiere un título"),
  });
};

export function ClientEditForm({ client, btnRef, saveClient }) {
  const validField = (value, error) => {
    if (error) {
      return "is-invalid";
    } else if (value) {
      return "is-valid";
    }
    return "";
  };

  return (
    <>
      <Formik
        enableReinitialize={true}
        initialValues={client}
        validationSchema={ClientEditSchema(client.id)}
        onSubmit={(values) => {
          saveClient(values);
        }}
      >
        {({ values, handleSubmit, setFieldValue, errors }) => (
          <>
            <Form className="form form-label-right">
              <div className="form-group row">
                <div className="col-lg-6">
                  <div>
                    <label>Imagen Pequeña</label>
                  </div>
                  <input
                    id="file"
                    name="file"
                    type="file"
                    // name="pic"
                    accept=".png, .jpg, .jpeg"
                    onChange={(event) => {
                      setFieldValue("file", event.currentTarget.files[0]);
                    }}
                    className={`form-control ${validField(
                      values.file,
                      errors.file
                    )}`}
                  />
                  {errors.file ? (
                    <div className="invalid-feedback">{errors.file}</div>
                  ) : null}
                </div>
                <div className="col-lg-6">
                  <div>
                    <label>Imagen Grande</label>
                  </div>
                  <input
                    id="file2"
                    name="file2"
                    type="file"
                    // name="pic"
                    accept=".png, .jpg, .jpeg"
                    onChange={(event) => {
                      setFieldValue("file2", event.currentTarget.files[0]);
                    }}
                    className={`form-control ${validField(
                      values.file2,
                      errors.file2
                    )}`}
                  />
                  {errors.file ? (
                    <div className="invalid-feedback">{errors.file}</div>
                  ) : null}
                </div>
              </div>
              <div className="form-group row">
                <div className="col-lg-12">
                  <Field
                    name="client"
                    component={Input}
                    placeholder="Cliente"
                    label="Cliente"
                  />
                </div>
              </div>
              <button
                type="submit"
                style={{ display: "none" }}
                ref={btnRef}
                onSubmit={() => handleSubmit()}
              ></button>
            </Form>
          </>
        )}
      </Formik>
    </>
  );
}

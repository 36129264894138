import axios from "axios";

export const BASE_URL = "https://api.v1.qres.com.mx";
export const CATEGORY_URL = `${BASE_URL}/programs/category`;
export const PROGRAM_ADMIN_URL = `${BASE_URL}/program_admin`;
export const CATEGORIES_URL = `${BASE_URL}/programs/categories`;

export const getNameField = (field) => {
  switch (field) {
    case "full_date":
      return "date";
    default:
      return field;
  }
};

// CREATE =>  POST: add a new program to the server
export function createProgramCategory(program) {
  return axios.post(CATEGORY_URL, program, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });
}

// READ
export function getAllProgramsCategory() {
  return axios.get(CATEGORIES_URL);
}

export function getProgramCategoryById(programId) {
  return axios.get(`${CATEGORY_URL}/${programId}`);
}

// Method from server should return QueryResultsModel(items: any[], totalsCount: number)
// items => filtered/sorted result
export function findProgramsCategory(qp) {
  const params = {
    offset: qp.pageNumber - 1,
    limit: qp.pageSize,
    field: getNameField(qp.sortField),
    order: qp.sortOrder,
    search_fields: qp.searchFields.join(","),
    search: qp.search,
  };
  // console.log("params", params);
  // const response = axios.get(`${PROGRAMS_URL}`, { queryParams });
  const response = axios.get(`${CATEGORIES_URL}`, { params });
  // console.log(response);
  return response;
}

// UPDATE => PUT: update the program on the server
export function updateProgramCategory(program) {
  const id = program.get("id");
  return axios.post(`${CATEGORY_URL}/${id}`, program);
}

// DELETE => delete the program from the server
export function deleteProgramCategory(programId) {
  return axios.delete(`${CATEGORY_URL}/${programId}`);
}

// // DELETE Products by ids
// export function deleteProducts(ids) {
//   return axios.post(`${PRODUCTS_URL}/deleteProducts`, { ids });
// }

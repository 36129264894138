import { createSlice } from "@reduxjs/toolkit";

const initialProgramsModuleState = {
  listLoading: false,
  actionsLoading: false,
  totalCount: 0,
  entities: null,
  programModuleForEdit: undefined,
  lastError: null,
};
export const callTypes = {
  list: "list",
  action: "action",
};

export const programsModuleSlice = createSlice({
  name: "programs_module",
  initialState: initialProgramsModuleState,
  reducers: {
    catchError: (state, action) => {
      // const errorResponse = action.payload.error.response.data[0];
      // console.log(action.payload);

      state.error = `${action.type}: ${action.payload.error}`;
      if (action.payload.callType === callTypes.list) {
        state.listLoading = false;
      } else {
        state.actionsLoading = false;
      }
      window.location = "/logout";
    },
    startCall: (state, action) => {
      state.error = null;
      if (action.payload.callType === callTypes.list) {
        state.listLoading = true;
      } else {
        state.actionsLoading = true;
      }
    },
    // getProgramsById
    programModuleFetched: (state, action) => {
      state.actionsLoading = false;
      state.programModuleForEdit = action.payload.programModuleForEdit;
      state.error = null;
    },
    // findPrograms
    programsModuleFetched: (state, action) => {
      const { count, data } = action.payload;
      state.listLoading = false;
      state.error = null;
      state.entities = data;
      state.totalCount = count;
    },
    // createProgram
    programModuleCreated: (state, action) => {
      state.actionsLoading = false;
      state.error = null;
      state.entities.push(action.payload.program_module);
    },
    // updateProgram
    programModuleUpdated: (state, action) => {
      state.error = null;
      state.actionsLoading = false;
      state.entities = state.entities.map((entity) => {
        if (entity.id === action.payload.program_module.id) {
          return action.payload.program_module;
        }
        return entity;
      });
    },
    // deleteProgram
    programModuleDeleted: (state, action) => {
      state.error = null;
      state.actionsLoading = false;
      state.entities = state.entities.filter(
        (el) => el.id !== action.payload.id
      );
    },
    // deletePrograms
    programsModuleDeleted: (state, action) => {
      state.error = null;
      state.actionsLoading = false;
      state.entities = state.entities.filter(
        (el) => !action.payload.ids.includes(el.id)
      );
    },
    // programsUpdateState
    programsModuleStatusUpdated: (state, action) => {
      state.actionsLoading = false;
      state.error = null;
      const { ids, status } = action.payload;
      state.entities = state.entities.map((entity) => {
        if (ids.findIndex((id) => id === entity.id) > -1) {
          entity.status = status;
        }
        return entity;
      });
    },
  },
});

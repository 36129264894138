import React from "react";
import { Route } from "react-router-dom";
import { useSubheader } from "../../../_metronic/layout";
import { ModulesDetailLoadingDialog } from "./modules-detail-loading-dialog/ModulesDetailLoadingDialog";
import { ModulesDetailUIProvider } from "./ModulesDetailUIContext";
import { ModulesDetailDeleteDialog } from "./modules-detail-delete-dialog/ModulesDetailDeleteDialog";
import { ModulesDetailCard } from "./ModulesDetailCard";

export default function ModulesDetailPage({
  history,
  match: {
    params: { module },
  },
}) {
  const suhbeader = useSubheader();
  suhbeader.setTitle("Listado Detalles de Modulo");
  const ModulesDetailUIEvents = {
    newModulesDetailButtonClick: (module) => {
      history.push(`/programa/modulo/detalle/${module}/nuevo`);
    },
    openEditModulesDetailPage: (module, id) => {
      history.push(`/programa/modulo/detalle/${module}/${id}/editar`);
    },
    openDeleteModulesDetailDialog: (module, id) => {
      history.push(`/programa/modulo/detalle/${module}/${id}/eliminar`);
    },
  };

  return (
    <ModulesDetailUIProvider modulesDetailUIEvents={ModulesDetailUIEvents}>
      <ModulesDetailLoadingDialog />
      <Route path="/programa/modulo/detalle/:module/:id/eliminar">
        {({ history, match }) => {
          return (
            <ModulesDetailDeleteDialog
              show={match != null}
              id={match && match.params.id}
              onHide={() => {
                history.push(`/programa/modulo/detalle/${module}`);
              }}
              module={module}
            />
          );
        }}
      </Route>
      <ModulesDetailCard module={module} />
    </ModulesDetailUIProvider>
  );
}

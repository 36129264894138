import axios from "axios";

export const BASE_URL = "https://api.v1.qres.com.mx"
export const BLOG_URL = `${BASE_URL}/new`;
export const BLOGS_URL = `${BASE_URL}/news`;

export const getNameField = (field) => {
  switch (field) {
    case 'full_date':
      return "date"
    default:
      return field
  }
}

// CREATE =>  POST: add a new blog to the server
export function createBlog(blog) {
  return axios.post(BLOG_URL, blog, {
    headers: {
      'Content-Type': 'multipart/form-data'
    }
  });
}

// READ
export function getAllBlogs() {
  return axios.get(BLOGS_URL);
}

export function getBlogById(blogId) {
  return axios.get(`${BLOG_URL}/${blogId}`);
}

// Method from server should return QueryResultsModel(items: any[], totalsCount: number)
// items => filtered/sorted result
export function findBlogs(qp) {
  // console.log("queryParams", qp);
  const params = {
    offset: qp.pageNumber - 1,
    limit: qp.pageSize,
    field: getNameField(qp.sortField),
    order: qp.sortOrder,
    search_fields: qp.searchFields.join(','),
    search: qp.search
  }
  // console.log("params", params);
  // const response = axios.get(`${BLOGS_URL}`, { queryParams });
  const response = axios.get(`${BLOGS_URL}`, { params });
  // console.log(response);
  return response;
}

// UPDATE => PUT: update the blog on the server
export function updateBlog(blog) {
  const id = blog.get("id");
  return axios.post(`${BLOG_URL}/${id}`, blog, {
    headers: {
      'Content-Type': 'multipart/form-data'
    }
  });
}

// DELETE => delete the product from the server
export function deleteBlog(blogId) {
  return axios.delete(`${BLOG_URL}/${blogId}`);
}

// // DELETE Products by ids
// export function deleteProducts(ids) {
//   return axios.post(`${PRODUCTS_URL}/deleteProducts`, { ids });
// }

// React bootstrap table next =>
// DOCS: https://react-bootstrap-table.github.io/react-bootstrap-table2/docs/
// STORYBOOK: https://react-bootstrap-table.github.io/react-bootstrap-table2/storybook/index.html
import React, { useEffect, useMemo } from "react";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory, {
  PaginationProvider,
} from "react-bootstrap-table2-paginator";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import * as actions from "../_redux/programsCategoryActions";
import * as uiHelpers from "../ProgramsCategoryUIHelpers";
import {
  getHandlerTableChange,
  NoRecordsFoundMessage,
  PleaseWaitMessage,
  sortCaret,
} from "../../../../_metronic/_helpers";
import * as columnFormatters from "./column-category-formatters";
import { Pagination } from "../../../../_metronic/_partials/controls";
import { useProgramsCategoryUIContext } from "../ProgramsCategoryUIContext";

export function ProgramsCategoryTable() {
  // console.log(history);
  // Programs UI Context
  const programsCategoryUIContext = useProgramsCategoryUIContext();
  const programsCategoryUIProps = useMemo(() => {
    return {
      ids: programsCategoryUIContext.ids,
      setIds: programsCategoryUIContext.setIds,
      queryParams: programsCategoryUIContext.queryParams,
      setQueryParams: programsCategoryUIContext.setQueryParams,
      openEditProgramCategoryPage:
        programsCategoryUIContext.openEditProgramCategoryPage,
      openDeleteProgramCategoryDialog:
        programsCategoryUIContext.openDeleteProgramCategoryDialog,
    };
  }, [programsCategoryUIContext]);

  // Getting curret state of programs list from store (Redux)
  const { currentState } = useSelector(
    (state) => ({ currentState: state.programs_category }),
    shallowEqual
  );
  const { totalCount, entities, listLoading } = currentState;
  // Programs Redux state
  const dispatch = useDispatch();
  useEffect(() => {
    // clear selections list
    programsCategoryUIProps.setIds([]);
    // server call by queryParams
    dispatch(
      actions.fetchProgramsCategory(programsCategoryUIProps.queryParams)
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [programsCategoryUIProps.queryParams, dispatch]);
  // Table columns
  const columns = [
    {
      dataField: "category",
      text: "Categoria",
      sort: true,
      sortCaret: sortCaret,
    },
    {
      dataField: "action",
      text: "Opciones",
      formatter: columnFormatters.ActionsColumnFormatter,
      formatExtraData: {
        openEditProgramCategoryPage:
          programsCategoryUIProps.openEditProgramCategoryPage,
        openDeleteProgramCategoryDialog:
          programsCategoryUIProps.openDeleteProgramCategoryDialog,
      },
      classes: "text-right pr-0",
      headerClasses: "text-right pr-3",
      style: {
        minWidth: "100px",
      },
    },
  ];

  const customTotal = (from, to, size) => (
    <span className="react-bootstrap-table-pagination-total">
      Mostrando {from} a {to} de {size} Registros
    </span>
  );

  // Table pagination properties
  const paginationOptions = {
    custom: true,
    totalSize: totalCount,
    sizePerPageList: uiHelpers.sizePerPageList,
    sizePerPage: programsCategoryUIProps.queryParams.pageSize,
    page: programsCategoryUIProps.queryParams.pageNumber,
    paginationTotalRenderer: customTotal,
  };

  const entitiesClean = (entities || []).filter(function(element) {
    return element !== undefined;
  });
  return (
    <>
      <PaginationProvider pagination={paginationFactory(paginationOptions)}>
        {({ paginationProps, paginationTableProps }) => {
          return (
            <Pagination
              isLoading={listLoading}
              paginationProps={paginationProps}
            >
              <BootstrapTable
                wrapperClasses="table-responsive"
                classes="table table-head-custom table-vertical-center overflow-hidden"
                bootstrap4
                bordered={false}
                remote
                keyField="id"
                data={entitiesClean === null ? [] : entitiesClean}
                columns={columns}
                defaultSorted={uiHelpers.defaultSorted}
                onTableChange={getHandlerTableChange(
                  programsCategoryUIProps.setQueryParams
                )}
                {...paginationTableProps}
              >
                <PleaseWaitMessage entities={entitiesClean} />
                <NoRecordsFoundMessage entities={entitiesClean} />
              </BootstrapTable>
            </Pagination>
          );
        }}
      </PaginationProvider>
    </>
  );
}

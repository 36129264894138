import axios from "axios";

export const BASE_URL = "https://api.v1.qres.com.mx"
export const MODULE_URL = `${BASE_URL}/programs/module`;
export const MODULES_URL = `${BASE_URL}/programs/modules`;

export const getNameField = (field) => {
  switch (field) {
    case 'full_date':
      return "date"
    default:
      return field
  }
}

// CREATE =>  POST: add a new program to the server
export function createProgramModule(programId, values) {
  return axios.post(`${MODULE_URL}/${programId}`, values);
}

// READ
export function getAllProgramsModule() {
  return axios.get(MODULES_URL);
}

export function getProgramModuleById(programId) {
  return axios.get(`${MODULE_URL}/${programId}`);
}

// Method from server should return QueryResultsModel(items: any[], totalsCount: number)
// items => filtered/sorted result
export function findProgramsModule(program, qp) {
  const params = {
    offset: qp.pageNumber - 1,
    limit: qp.pageSize,
    field: getNameField(qp.sortField),
    order: qp.sortOrder,
    search_fields: qp.searchFields.join(','),
    search: qp.search
  }
  // console.log("params", params);
  // const response = axios.get(`${PROGRAMS_URL}`, { queryParams });
  const response = axios.get(`${MODULES_URL}/?programs_id=${program}`, { params });
  // console.log(response);
  return response;
}

// UPDATE => PUT: update the program on the server
export function updateProgramModule(program) {
  const id = program.get("id");
  return axios.post(`${MODULE_URL}/update/${id}`, program);
}

// DELETE => delete the program from the server
export function deleteProgramModule(programId) {
  return axios.delete(`${MODULE_URL}/${programId}`);
}

// // DELETE Products by ids
// export function deleteProducts(ids) {
//   return axios.post(`${PRODUCTS_URL}/deleteProducts`, { ids });
// }
